@import 'assets/styles/variables';

.radio__button {
  &__contianer {
    display: flex;
  }
  &__btn {
    align-items: center;
    border-radius: 50%;
    border: 1px solid $elementos-ui-azul-01;
    display: flex;
    height: 1rem;
    justify-content: center;
    margin-right: 0.4rem;
    position: relative;
    width: 1rem;
    background-color: $color-white;

    &::after {
      -moz-transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;
      background-color: $elementos-ui-azul-01;
      border-radius: 50%;
      content: '';
      height: 0.5rem;
      transform: scale(0);
      transition: all 0.2s ease;
      width: 0.5rem;
    }
    &--selected {
      border: 1px solid $elementos-ui-azul-01;

      &::after {
        transform: scale(1);
      }
    }
  }
}
