@import 'assets/styles/variables';

.sub-details-status {
  display: flex;
  justify-content: space-between;

  &__label {
    color: $color-text-primary;
    font-family: ConnectLigera;
    margin: 0;
    font-size: 0.95rem;
    line-height: 1.125rem;
    letter-spacing: 0.05rem;
  }

  &__value {
    font-size: 1rem;
    font-family: ConnectNegrita, sans-serif;
    line-height: 1.3rem;
    
    &--highlight {
      color: $elementos-ui-naranja-01;
    }
  }
}
