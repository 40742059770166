@import 'assets/styles/variables';

.card__invitation__app {
  &__container {
    background-color: $light-component-background-01;
    border: 1px solid #ebf1f5;
    border-radius: 0.625rem;
    padding: 1.438rem 1.438rem 2rem;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    h1 {
      font-size: 1.563rem;
      line-height: 2.188rem;
      color: #254E70;
    }
  }
  &__message {
    margin: 0.711rem 0 2.022rem;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.44px;
  }
}
