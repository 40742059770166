@import 'assets/styles/mixin-titles';
@import 'assets/styles/variables';
@import 'assets/styles/mixin-skeleton';
@import 'assets/styles/animations';

h1 {
  @include title-h1;
}

h2 {
  @include title-h2;
}

h3 {
  @include title-h3;
}

h4 {
  @include title-h4;
}

h5 {
  @include title-h5;
}

h6 {
  @include title-h6;
}
.title__header {
  @include title-header;
}

.container__generic {
  margin: 0 auto;
  max-width: 38rem;
  width: 100%;
}

.label__generic {
  color: $color-text-primary;
  font-family: Inter-SemiBold, sans-serif;
  font-size: $font-size-m;
  margin-bottom: 0.5rem;
  text-align: left;
}

.skeleton {
  @include skeleton;
}

.overlay__mask {
  bottom: 0;
  cursor: auto;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.bounceOut {
  @include bounceOut;
  &::after {
    content: '';
    position: absolute;
    background-color: $bg-error;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
