@import 'assets/styles/variables';

.popup__informative {
  &__container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    min-height: 23.75rem;
    gap: 0.5rem;
  }
  &__body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
  &--error {
    background-color: $alertas-error;
  }
  &--warning {
    background-color: $bg-warning;
  }
  &--successful {
    background-color: $alertas-exito;
  }
  &__close {
    margin: 0.438rem 0.438rem 0.438rem auto;
  }
  &__icon {
    height: 3.75rem;
    width: 3.75rem;
  }
  &__subtitle {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
  }
  &__text {
    margin: 0;
    font-family: ConnectLigera, sans-serif;
    font-size: 14px;
    font-weight: 200;
    line-height: 18px;
    letter-spacing: 0.800000011920929px;
    text-align: left;
    color: $color-white;
  }
  &__button {
    width: max-content;
    &.warning {
      color: $alertas-advertencia;
    }
    &.error {
      color: $alertas-error;
    }
    &.successful {
      color: $alertas-exito;
    }
  }
}
