.add__property {
  &__container {
    padding: 1.875rem 0.875rem 9.563rem;
    max-width: 26.688rem;
    margin: 0 auto;
  }

  &__content {
    padding: 0 0.875rem;
  }

  &__icon {
    margin-bottom: 1.25rem;
    height: 6.25rem;
    width: 6.25rem;
  }

  &__text {
    margin: 1.25rem 0;
    &.m--small {
      margin: 0.625rem 0 1.25rem 0;
    }
  }

  &__form {
    margin-bottom: 3.875rem;
    &__quantity {
      margin-bottom: 1.25rem;
    }
  }
}

.mt-30 {
  margin-top: 30px;
}
