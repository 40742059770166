.image {
  &--small {
    height: 1.875rem;
    width: 5rem;
  }
  &--medium {
    height: 2.391rem;
    width: 6.625rem;
  }
  &--big {
    height: 5rem;
    width: 10rem;
  }
  &--custom {
    height: inherit;
    width: inherit;
  }
}
