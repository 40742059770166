@import 'assets/styles/variables';

.plan__detail {
  &__container {
    margin: 0 auto;
    max-width: 26.688rem;
  }
  &__content {
    padding: 0 1.5rem;
  }
  &__nav__container {
    display: flex;
    justify-content: space-between;
  }

  &__info__header {
    display: flex;
    flex-direction: column;

    img {
      margin-bottom: 0.625rem;
      width: 5.079rem;
      height: 5.188rem;
    }
  }

  &__title {
    color: $elementos-ui-naranja-01;
    strong {
      font-family: Inter-Bold, sans-serif;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
  &__chips__container {
    display: flex;
    padding: 2.25rem 0 1.25rem 0;
    text-transform: lowercase;
    & > div {
      margin-right: 0.5rem;
    }
  }
  &__accordion__container {
    padding: 0 1.5rem;
    margin: 1.875rem 0;
  }
  &__info__properties {
    margin-bottom: 1.875rem;
    line-height: 1.25rem;
    p {
      margin: 0;
      &.bold {
        font-family: Inter-Bold, sans-serif;
        font-weight: 500;
      }
    }
    .title {
      &__principal {
        font-family: Inter-Bold, sans-serif;
        font-weight: 500;
        color: $color-text-disabled;
      }
      &__secondary {
        color: $color-text-disabled;
      }
    }
  }
  &__advantages__container {
    margin: 1.875rem 0 2.438rem;
    padding: 0 1rem;
    .advantages__title {
      font-size: 1.25rem;
      font-weight: 200;
      margin-bottom: 1.25rem;
    }
    .advantages__list {
      padding: 0 0 0 1rem;
      margin: 1.25rem 1.375rem 2rem 2.188rem;
    }
  }
  &__link {
    color: $accent-orange-01;
    margin-left: 2.188rem;
  }
}
