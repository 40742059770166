@import 'assets/styles/variables';

.item__card__skeleton {
  &__container {
    display: flex;
    flex-direction: column;
    background: $bg-skeleton;
    padding: 0.688rem 0.625rem 0.875rem 0.938rem;
    border-radius: 0.625rem;
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 0.313rem;
    .title {
      height: 1.156rem;
      width: 4.188rem;
      box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
    }
    .button {
      height: 1.563rem;
      width: 1.563rem;
      box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
      margin-left: auto;
      border-radius: 0.313rem;
    }
  }
  &__chip {
    display: flex;
    margin-bottom: 0.313rem;

    .chip {
      border-radius: 0.25rem;
      height: 1.25rem;
      width: 3.125rem;
      margin-right: 0.313rem;
      box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
    }
  }
  &__tag {
    border-radius: 1rem;
    height: 1.25rem;
    width: 5rem;
    box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
  }
}
