@import 'assets/styles/variables';

.purchase__order__container {
  text-align: left;
  padding: 1.5rem 1rem 0 1rem;
  &__button {
    padding: 2.5rem 1rem;
    position: sticky;
    bottom: 0;
  }

  &__list {
    width: 240px;
    margin: 0 auto;
    .purchase__order__list {
      &--bold {
        font-family: ConnectNegrita, sans-serifF;
      }

      &__link {
        font-family: ConnectLigeraItalica, sans-serifF;
        color: $elementos-ui-naranja-01;
      }
    }
  }
}

.payment__margin {
  margin: 1.875rem 0;
}

.purchase__order__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .container__coupon {
    align-items: flex-start;
    display: flex;
    margin: 0 0 4.5rem 0;

    .button__coupon {
      border-radius: 0.5rem;
      margin: 0 0 0 0.563rem;
      min-height: 3.125rem;
      padding: 0.75rem;
      width: 7.625rem;
      border: 1px solid #566976;
      text-transform: lowercase;
      justify-content: center;
    }
  }
  .container__list {
    margin-top: 3rem;
  }

  .sumary__totlas__divider {
    margin: 1rem 0 1.563rem 0;
    padding: 0 0.938rem;
  }
  .payment__periodicity__container {
    margin-top: 26px;
  }
  .summary__information__info__values {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
.purchase__order__padding {
  padding: 0 1rem;
}
