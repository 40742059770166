@import 'assets/styles/variables';
.item__card {
  &__container {
    position: relative;
    cursor: default;
    &.is__clickable {
      cursor: pointer;
    }
  }
  &__more__options {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }
  &__content {
    padding: 0.688rem 0.625rem 0.875rem 0.938rem;

    .item__card__title {
      display: flex;
      flex-direction: column;
      margin: 0.313rem 1.563rem 0 0;
      font-family: ConnectNegrita;
      font-size: 0.875rem;
      line-height: 1.5rem;
      letter-spacing: 0.0125rem;
      text-transform: uppercase;

      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &__container {
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        justify-content: space-between;
        letter-spacing: 0.44px;
        line-height: 1.125rem;
        margin-bottom: 0.313rem;
      }
    }
    .item__card__chips {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}
