.user-details {
  margin-bottom: 2rem;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  
  &__name {
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &__email {
    color: #777;
    font-size: 1rem;
  }
}