@import 'assets/styles/variables';

.active__plan__container {
  text-align: left;
  padding: 1.56rem 1rem 0 1rem;
}

.active__plan__content {
  margin-top: 3rem;
}
.active__plan__properties {
  display: flex;
  flex-direction: column;
  margin: 56px 0;
  padding: 0 1rem;
  gap: 24px;
  &__title {
    p {
      margin: 0.5rem 0 0 0;
    }
  }
}
.active__plan__padding {
  padding: 0 1.875rem;
}