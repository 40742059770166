@import 'assets/styles/variables';
.card__body {
  &__container {
    background: $color-white;
    position: relative;
    overflow: hidden;
    width: 100%;
    &.overflowIsVisible {
      overflow: visible;
    }
  }
  &__content {
    border: 2px solid $elementos-ui-azul-05;
    padding: 2rem 1rem;
    &.outline {
      border: none;
    }
  }
}
