.history {
  &__container {
    display: flex;
    flex-direction: column;
    margin: 0 auto 2.688rem auto;
    max-width: 26.688rem;
    padding: 0.75rem 0 0;
    width: 100%;
  }
}
