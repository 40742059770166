@import 'assets/styles/variables';
@import 'assets/styles/mixin-base';

.accordion__detail {
  &__container {
    border-top: 1px solid $elementos-ui-naranja-03;
    padding-top: 0.5rem;
    transition: all 0.5s ease;
    z-index: 1;
    &.is--open {
      border-top: 1px solid $elementos-ui-azul-01;
    }
  }

  &__taps__container {
    position: relative;
    display: flex;
    gap: 1rem;
    overflow-x: scroll;
    @include for-other-device {
      overflow-x: hidden;
    }
    .border {
      height: 1px;
      background-color: #a3bed6;
      bottom:0;
      left: 0;
      right: 0;
      position: absolute;
    }
  }
  &__content {
    overflow: hidden;
    padding-top: 1rem;
    &__list {
      display: flex;
      flex-direction: column;
      margin-top: 0.5rem;
    }
  }

  &__container__list {
    margin: 1rem 0;
    font-size: 1rem;
    padding: 0 1rem;
  }

  &__more__info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 200;
    line-height: 1.5rem;
    letter-spacing: 0.05rem;
    padding: 0 1rem;
    a {
      font-family: ConnectLigeraItalica, sans-serif;
      color: $elementos-ui-naranja-01;
    }
  }
}
