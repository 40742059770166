@import 'assets/styles/variables';

.popup__required__validation {
  align-items: center;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  padding: 2.031rem 1.438rem 3.875rem 1.438rem;
  align-items: flex-start;
  background-color: $elementos-ui-azul-05;
  &__icon {
    margin-bottom: 0.938rem;
    height: 3.141rem;
    width: 3.472rem;
  }
  &__btn {
    width: max-content;
  }
}
