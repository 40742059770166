.header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__logo {
  display: block;
  margin: 0 0 0.3rem auto;
  width: 8.3125rem;
  height: 2rem;
  &.pin {
    width: 3rem;
    height: 3rem;
  }
}
