@import 'assets/styles/variables';

.summary__information__short__link {
  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 1rem;
  }

  &__divider {
    height: 1px;
    width: 100%;
    background-color: $elementos-ui-azul-03;
    &.dashed {
      background-color: transparent;
      border-top: 1px dashed $elementos-ui-azul-03;
    }
  }

  &__content {
    width: 100%;
    &__group {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
    }
    &__detail {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .product__type {
        display: flex;
        gap: 8px;
        font-family: ConnectLigera, sans-serif;
        letter-spacing: 0.8px;
        line-height: 1rem;
        font-size: 14px;
        img {
          width: 1rem;
          height: 1rem;
        }
      }
      .product__price {
        font-family: ConnectLigera, sans-serif;
        letter-spacing: 0.8px;
        line-height: 1rem;
        font-size: 12px;
      }
    }
    &.title--header {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.8px;
      font-family: ConnectItalica, sans-serif;
    }
    display: flex;
    justify-content: space-between;
    &.color--primary {
      color: $elementos-ui-naranja-01;
    }
    &.coupon__terms {
      a {
        font-size: 0.875rem;
        font-family: ConnectLigera, sans-serif;
        line-height: 1.125rem;
        margin-top: 0.5rem;
        cursor: pointer;
        color: $elementos-ui-azul-01;
      }
    }
  }

  &__label,
  &__value {
    font-size: 1rem;
    line-height: 24px;
    letter-spacing: 0.8px;
    font-family: ConnectLigera, sans-serif;
    &.orange {
      color: #f15b2b;
    }

    &.bold {
      font-family: ConnectNegrita, sans-serif;
      font-size: 1.125rem;
      line-height: 1.5rem;
      letter-spacing: 0.05rem;
    }
  }

  &__decorator__container {
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    .summary__information__short__link__decorator__step {
      height: 1rem;
      width: 1rem;
      border: 2px solid $elementos-ui-azul-05;
      border-radius: 50%;
      background-color: $fondos-celeste;
    }
  }
}
