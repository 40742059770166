.item__sub {
  &__container {
    margin-bottom: 0.625rem;
  }
  &__content {
    padding: 1rem;
    position: relative;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #f0f4f8;
      cursor: pointer;
    }
  }
  &__title {
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    line-height: 1.3rem;
    .title--bold {
      font-family: ConnectNegrita, sans-serif;
      text-transform: uppercase;
      &.row {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &__detail {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #586983;
    font-family: ConnectLigera, sans-serif;
    line-height: 18px;

  }
  &__status {
    display: flex;
    justify-content: space-between;
  }
}
