@import 'assets/styles/variables';

.generic__section__error {
  &__container {
    position: relative;
    margin-top: 1.25rem;
    &::before {
      content: '';
      border-radius: 50%;
      height: 6.75rem;
      position: absolute;
      width: 6.75rem;
      z-index: 0;
      right: -3.063rem;
      top: 1.125rem;
    }

    &--primary {
      &::before {
        background-color: $accent-orange-02;
      }
    }
    &--secondary {
      &::before {
        background-color: $accent-orange-03;
      }
    }
    &--tertiary {
      &::before {
        background-color: $accent-orange-05;
      }
    }
  }
  &__content {
    align-items: center;
    background: rgba(235, 241, 245, 0.8);
    backdrop-filter: blur(0.938rem);
    border-radius: 0.625rem;
    display: flex;
    padding: 1.375rem 2.313rem 1.688rem 1.563rem;
    width: 100%;
    justify-content: space-between;
  }
  &__icon {
    height: 3.188rem;
    width: 3.5rem;
  }
  &__info {
    max-width: 10.688rem;
    display: flex;
    flex-direction: column;
    .generic__section__error__title {
      margin: 0 0 0.625rem 0;
      line-height: 1.25rem;
      letter-spacing: 0.25px;
      &--small {
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0.4px;
        font-family: Inter-Light, sans-serif;
      }
    }
    .generic__section__error__link {
      color: $accent-orange-04;
      margin-top: 0.625rem;
      line-height: 1.063rem;
      letter-spacing: 0.1px;
    }
  }
}
