.add__payment__method {
  &__container {
    padding: 1.875rem;
    .container__element__stripe {
      display: flex;
      &.small {
        width: 7.4rem;
        margin-left: 0.563rem;
      }
      .container__button__delete {
        position: absolute;
        top: 1rem;
        right: 0.8rem;
      }
    }
    .btn__add__payment__method {
      margin-top:4rem;
    }
  }
}
