@import 'assets/styles/variables';
@import 'assets/styles/animations';

.popup__delete {
  background-color: $alertas-error;
  border-radius: 0.625rem;

  &__header {
    align-items: flex-start;
    display: flex;
    padding: 1rem 1rem 0;

    &__icon {
      height: 1.188rem;
      margin-top: 0.5rem;
      width: 1.313rem;
    }
    &__info {
      display: flex;
      flex-direction: column;
      margin: 0 auto 0 1rem;
      max-width: 11.688rem;

      span {
        color: $light-component-background-01;
        font-size: 1rem;
      }
      &__button {
        background-color: $light-component-background-01;
        border-radius: 0.5rem;
        border: none;
        color: $alertas-error;
        cursor: pointer;
        font-size: 0.875rem;
        font-weight: 700;
        margin-top: 0.75rem;
        outline: none;
        padding: 0.625rem;
        text-transform: uppercase;
        position: relative;
        &.disabled {
          cursor: none;
        }
      }
    }
  }
  &__title {
    color: $light-component-background-01;
    font-family: Inter-SemiBold, sans-serif;
    margin: 2.063rem 0 0.938rem 0;
    padding-top: 0.813rem;
    padding: 0 1rem;

    &__divider {
      border-top: 1px solid $light-component-background-01;
      margin: 0 0 0.813rem 0;
    }
    span {
      text-transform: uppercase;
    }
  }
  &__detail {
    background-color: $bg-error;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    padding: 1.125rem 1rem;

    &__chips {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0.5rem;
      gap: 0.5rem;
    }

    &__card {
      display: flex;
      flex-direction: column;
      img {
        height: 1rem;
        width: 3.125rem;
      }
      span {
        height: 2.62rem;
        display: flex;
        align-items: center;
        font-size: 1rem;
      }
    }
  }
  &__loader {
    @include spin;
    position: relative;
    border-top: 4px solid rgba(255, 255, 255, 0.2);
    border-right: 4px solid rgba(255, 255, 255, 0.2);
    border-bottom: 4px solid rgba(255, 255, 255, 0.2);
    border-left: 4px solid $alertas-error;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    &__container {
      border-radius: 0.5rem;
      background-color: $light-component-background-01;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:after {
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  }
}
