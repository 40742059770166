@import 'assets/styles/variables';

.button__add__item {
  align-items: center;
  background-color: transparent;
  border: 0;
  outline: none;
  color: $elementos-ui-naranja-01;
  cursor: pointer;
  display: flex;
  font-family: ConnectNegrita, Prompt;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.8px;
  text-transform: uppercase;

  &--secondary {
    color: $accent-orange-04;
  }

  &__icon {
    align-items: center;
    border-radius: 50%;
    border: 1px solid $elementos-ui-naranja-01;
    display: flex;
    height: 1.146rem;
    justify-content: center;
    margin-left: 0.5rem;
    position: relative;
    width: 1.146rem;

    &::after {
      border-radius: 8px;
      border: 1px solid $elementos-ui-naranja-01;
      content: '';
      height: 0.5rem;
      position: absolute;
    }

    &::before {
      border-radius: 8px;
      border: 1px solid $elementos-ui-naranja-01;
      content: '';
      position: absolute;
      width: 0.5rem;
    }

    &--secondary {
      border: 1px solid $accent-orange-04;

      &::after,
      &::before {
        border: 1px solid $accent-orange-04;
      }
    }
  }

  &:hover {
    color: $elementos-ui-naranja-03;

    .button__add__item__icon {
      border: 1px solid $elementos-ui-naranja-03;

      &::after,
      &::before {
        border: 1px solid $elementos-ui-naranja-03;
      }
    }
  }
}
