.offline__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 150px 0 0;
  .offline__content {
    width: 328px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .offline__content__text {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.8px;
    text-align: center;
    margin: 0;
    &.center {
      text-align: center;
    }
  }
  .offline__content__return {
    font-size: 12px;
    text-align: center;
    margin: 0;
    line-height: 16px;
    font-family: ConnectNegrita, sans-serif;
  }
  .offline__content__title {
    margin-top: 32px;
    font-size: 18px;
    line-height: 24px;
    font-family: ConnectNegra, sans-serif;
    text-align: center;
    margin-top: 48px;
    letter-spacing: 1.3px;
  }
  .offline__content__contact {
    display: flex;
    gap: 16px;
    justify-content: center;
  }
  .offline__footer {
    width: 100%;
    margin-top: auto;
  }

  .offline__button {
    background-color: #001d3d;
    outline: none;
    padding: 12px 32px 12px 32px;
    color: #ffffff;
    box-shadow: 0px 0px 0px 0px #001d3d1a;
    box-shadow: 2px 3px 8px 0px #001d3d1a;
    box-shadow: 6px 13px 14px 0px #001d3d17;
    box-shadow: 14px 29px 19px 0px #001d3d0d;
    box-shadow: 25px 51px 23px 0px #001d3d03;
    box-shadow: 40px 79px 25px 0px #001d3d00;
    text-transform: uppercase;
    width: max-content;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.3px;
    font-family: ConnectNegra, sans-serif;
    border-radius: 100px;
    border: none;
    cursor: pointer;
    margin: 15px auto;
    text-decoration: none;
  }
}
