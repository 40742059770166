@import 'assets/styles/animations';
.services__history__skeleton__container {
  display: flex;
  flex-direction: column;
  padding: 0rem 0.875rem 4.906rem;
  @include fadeIn;

  .filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.875rem;
    &__status {
      border-radius: 0.625rem;
      height: 3.125rem;
      width: 7.938rem;
    }
    &__calendary {
      border-radius: 0.625rem;
      height: 3.125rem;
      width: 4.938rem;
    }
  }
}
