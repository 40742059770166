@import 'assets/styles/variables';
.container__payment__header {
  &--none {
    display: none;
  }
}

.container__payment {
  text-align: left;
  padding: 5rem 0 0 0;
  &.shortLink {
    padding: 1rem 0 0 0;
  }
}

.payment__margin {
  margin: 1.875rem 0;
}

.content__payment {
  .container__list {
    margin-top: 3rem;
  }
  .container__elements__stripe {
    display: flex;
  }

  .container__element__stripe {
    &.small {
      width: 7.4rem;
      margin-left: 0.563rem;
    }
    .container__button__delete {
      position: absolute;
      top: 1rem;
      right: 0.8rem;
    }
  }
  .sumary__totlas__divider {
    margin: 1rem 0 1.563rem 0;
    padding: 0 0.938rem;
  }
  .payment__periodicity__container {
    margin-top: 26px;
  }
  .summary__information__info__values {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
  }
  &__button {
    margin-top: 4.5rem;
    position: sticky;
    bottom: 2rem;
    &.relative {
      position: relative;
    }
  }

  .message__trial {
    text-align: center;
    margin: 1.5rem 0 0 0;
  }
  .form__coupon__container {
    margin: 0;
  }
  &__short__link {
    margin-bottom: 56px;
  }
}
.payment__padding {
  padding: 0 1.875rem;
}

.StripeElement--focus {
  ~ .input__label {
    transform: translate(-3px, -9px) scale(0.9);
  }
}

.stripe__badge {
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  img {
    width: 100%;
    height: auto;
  }
}
