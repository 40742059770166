@import 'assets/styles/variables';

.history__card__skeleton {
  background: $bg-skeleton;
  border-radius: 0.625rem;
  padding: 0.938rem 2.438rem 0.938rem 1.25rem;
  margin-bottom: 0.625rem;
  .titles {
    display: flex;
    justify-content: space-between;
    .info__service {
      display: flex;
      flex-direction: column;
      .type__service {
        box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
        height: 1.3rem;
        width: 7.625rem;
      }
      .id__service {
        box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
        height: 1rem;
        margin: 0.125rem 0;
        width: 5.625rem;
      }
    }
    .value {
      box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
      height: 1.3rem;
      width: 5rem;
    }
  }

  .detail__service {
    .data {
      box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
      height: 1rem;
      width: 5.5rem;
      margin: 0.3rem 0;
      &--small {
        width: 7.5rem;
      }
    }
  }

  .info__tag {
    display: flex;
    justify-content: space-between;
    margin-top: 0.625rem;
    .name {
      box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
      height: 1.25rem;
      width: 7.25rem;
      border-radius: 0.25rem;
    }
    .status {
      box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
      height: 1.25rem;
      width: 4.75rem;
      border-radius: 6.25rem;
    }
  }
}
// background: $bg-skeleton;
// box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
