@import 'assets/styles/variables';

.container__checkbox {
  align-items: center;
  display: flex;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  .checkbox__label {
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.05rem;
    position: relative;
  }
}
input.checkbox__input[type='checkbox'] {
  display: none;

  + label::before {
    content: '';
    cursor: pointer;
    width: 0.875rem;
    height: 0.875rem;
    float: left;
    border: 1px solid $accent-blue-04;
    background: $light-component-background-01;
    border-radius: 3px;
    margin-right: 0.5rem;
  }

  + label:hover::before {
    border: 1px solid $color-primary;
  }

  &:checked + label {
    &::after {
      content: '';
      width: 6px;
      height: 3px;
      border: 2px solid $light-component-background-01;
      position: absolute;
      left: 4px;
      top: 1px;
      border-right: 0;
      border-top: 0;
      margin-top: 3px;
      transform: rotate(-55deg);
    }

    &::before {
      border-color: $color-primary;
      background-color: $color-primary;
    }
  }

  &:disabled + label {
    color: $color-primary-disabled;
    cursor: default;

    &::after {
      border-color: $color-primary-disabled;
    }

    &::before {
      border-color: $color-disabled;
      cursor: default;
    }
  }
}
