@import 'assets/styles/variables';
@import 'assets/styles/animations';

.btn__button {
  color: $color-white;
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  outline: none;
  position: relative;
  width: 100%;
  overflow: hidden;
  text-transform: uppercase;
  justify-content: center;
  height: fit-content;

  &:disabled,
  .disabled {
    background-color: $elementos-ui-gris;
    pointer-events: none;
    box-shadow: none;
  }

  &.loading {
    cursor: default;
    color: transparent;

    & > *:not(.loader__container) {
      visibility: hidden;
    }
  }

  &--rounded {
    border-radius: 100px;
    &.btn__button {
      &--small {
        font-family: ConnectNegrita, sans-serif;
        padding: 0.5rem 1.5rem;
        font-size: 0.875rem;
        line-height: 1rem;
        letter-spacing: 0.05rem;
      }
      &--medium {
        font-family: ConnectNegra, sans-serif;
        padding: 0.75rem 2rem;
        font-size: 1.125rem;
        line-height: 1.5rem;
        letter-spacing: 0.08125rem;
      }
      &--large {
        font-family: ConnectNegra, sans-serif;
        padding: 1.25rem 3rem;
        font-size: 1.5rem;
        line-height: 1.5rem;
        letter-spacing: 0.08125rem;
      }
    }
  }
  &--square {
    border-radius: 0;
    &.btn__button {
      &--small {
        padding: 0.5rem 1rem;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0.03125rem;
      }
      &--medium {
        font-family: ConnectNegrita, sans-serif;
        padding: 1rem;
        font-size: 0.875rem;
        line-height: 1rem;
        letter-spacing: 0.05rem;
      }
      &--large {
        font-family: ConnectNegra, sans-serif;
        padding: 1.25rem 2rem;
        font-size: 1.125rem;
        line-height: 1.5rem;
        letter-spacing: 0.08125rem;
      }
    }
  }

  &--primary {
    &.btn__button__color--primary:not(.disabled) {
      background: $elementos-ui-naranja-01;
      &.btn__button--rounded {
        box-shadow: 0px 0px 0px 0px rgba(241, 91, 43, 0.1),
          2px 3px 8px 0px rgba(241, 91, 43, 0.1),
          6px 13px 14px 0px rgba(241, 91, 43, 0.09),
          14px 29px 19px 0px rgba(241, 91, 43, 0.05),
          25px 51px 23px 0px rgba(241, 91, 43, 0.01),
          40px 79px 25px 0px rgba(241, 91, 43, 0);
      }

      &:active {
        background: $elementos-ui-azul-01;
        box-shadow: none;
      }
      &:hover:not(.loading, :active) {
        background: $elementos-ui-naranja-03;
        &.btn__button--rounded {
          box-shadow: 0px 0px 0px 0px rgba(241, 91, 43, 0.1),
            0px 0px 1px 0px rgba(241, 91, 43, 0.1),
            2px 1px 2px 0px rgba(241, 91, 43, 0.09),
            4px 3px 3px 0px rgba(241, 91, 43, 0.05),
            7px 6px 4px 0px rgba(241, 91, 43, 0.01),
            10px 9px 4px 0px rgba(241, 91, 43, 0);
        }
      }
    }
    &.btn__button__color--secondary:not(.disabled) {
      background: $elementos-ui-azul-01;
      &.btn__button--rounded {
        box-shadow: 0px 0px 0px 0px rgba(0, 29, 61, 0.1),
          2px 3px 8px 0px rgba(0, 29, 61, 0.1),
          6px 13px 14px 0px rgba(0, 29, 61, 0.09),
          14px 29px 19px 0px rgba(0, 29, 61, 0.05),
          25px 51px 23px 0px rgba(0, 29, 61, 0.01),
          40px 79px 25px 0px rgba(0, 29, 61, 0);
      }

      &:active {
        background: $elementos-ui-naranja-01;
        box-shadow: none;
      }
      &:hover:not(.loading, :active) {
        background: $elementos-ui-azul-03;
        &.btn__button--rounded {
          box-shadow: 0px 0px 0px 0px rgba(0, 29, 61, 0.1),
            0px 0px 1px 0px rgba(0, 29, 61, 0.1),
            2px 1px 2px 0px rgba(0, 29, 61, 0.09),
            4px 3px 3px 0px rgba(0, 29, 61, 0.05),
            7px 6px 4px 0px rgba(0, 29, 61, 0.01),
            10px 9px 4px 0px rgba(0, 29, 61, 0);
        }
      }
    }
    &.btn__button__color--tertiary:not(.disabled) {
      background: $color-white;
      color: $elementos-ui-naranja-01;
      &.btn__button--rounded {
        box-shadow: 0px 0px 0px 0px rgba(0, 29, 61, 0.1),
          2px 3px 8px 0px rgba(0, 29, 61, 0.1),
          6px 13px 14px 0px rgba(0, 29, 61, 0.09),
          14px 29px 19px 0px rgba(0, 29, 61, 0.05),
          25px 51px 23px 0px rgba(0, 29, 61, 0.01),
          40px 79px 25px 0px rgba(0, 29, 61, 0);
      }

      &:active {
        background: $elementos-ui-naranja-01;
        box-shadow: none;
      }
      &:hover:not(.loading, :active) {
        background: $elementos-ui-azul-03;
        &.btn__button--rounded {
          box-shadow: 0px 0px 0px 0px rgba(0, 29, 61, 0.1),
            0px 0px 1px 0px rgba(0, 29, 61, 0.1),
            2px 1px 2px 0px rgba(0, 29, 61, 0.09),
            4px 3px 3px 0px rgba(0, 29, 61, 0.05),
            7px 6px 4px 0px rgba(0, 29, 61, 0.01),
            10px 9px 4px 0px rgba(0, 29, 61, 0);
        }
      }
    }
  }
  &--secondary {
    &.btn__button__color--primary:not(.disabled) {
      background: $color-white;
      color: $elementos-ui-naranja-01;
      border: 1px solid $elementos-ui-naranja-01;

      &.btn__button--rounded {
        box-shadow: 0px 0px 0px 0px rgba(241, 91, 43, 0.1),
          2px 3px 8px 0px rgba(241, 91, 43, 0.1),
          6px 13px 14px 0px rgba(241, 91, 43, 0.09),
          14px 29px 19px 0px rgba(241, 91, 43, 0.05),
          25px 51px 23px 0px rgba(241, 91, 43, 0.01),
          40px 79px 25px 0px rgba(241, 91, 43, 0);
      }

      &:active {
        color: $elementos-ui-azul-01;
        border: 1px solid $elementos-ui-azul-01;
        box-shadow: none;
      }
      &:hover:not(.loading, :active) {
        color: $elementos-ui-naranja-03;
        border: 1px solid $elementos-ui-naranja-03;
        &.btn__button--rounded {
          box-shadow: 0px 0px 0px 0px #001d3d1a;
          box-shadow: 0px 0px 1px 0px #001d3d1a;
          box-shadow: 2px 1px 2px 0px #001d3d17;
          box-shadow: 4px 3px 3px 0px #001d3d0d;
          box-shadow: 7px 6px 4px 0px #001d3d03;
          box-shadow: 10px 9px 4px 0px #001d3d00;
        }
      }
    }
  }
}

.loader {
  @include spin;
  position: relative;
  border-top: 4px solid rgba(255, 255, 255, 0.2);
  border-right: 4px solid rgba(255, 255, 255, 0.2);
  border-bottom: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  &.small {
    height: 20px;
    width: 20px;
  }
  &__container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:after {
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
}
