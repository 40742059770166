@import 'assets/styles/variables';
.edit__plan__information {
  &__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
  }
  &__detail__accordion {
    margin: 1.875rem;
  }
  &__title__administrator {
    letter-spacing: 0.25px;
    line-height: 1.25rem;
    margin: 0;
  }
  &__info__administrator {
    color: $color-text-disabled;
    font-style: italic;
    line-height: 1.063rem;
    margin: 0;
  }
  &__title__container {
    display: flex;
    flex-direction: column;
    margin: 24px 1.875rem 0 1.875rem;
    h1 {
      font-size: 1.5rem;
      line-height: 32px;
      letter-spacing: 1.3px;
      font-family: ConnectNegra, sans-serif;
      margin: 0 0 0.25rem 0;
    }
    img {
      height: 5.188rem;
      margin-right: 0.625rem;
      object-fit: cover;
      width: 5.079rem;
    }
    .title__product {
      color: #586983;
      letter-spacing: 0.8px;
      font-size: 0.75rem;
      line-height: 1.25rem;
      font-family: ConnectLigera, sans-serif;
      strong {
        font-family: ConnectNegrita, sans-serif;
      }
    }
    span {
      text-transform: uppercase;
      font-size: 12px;
      color: #586983;
      letter-spacing: 0.25px;
      line-height: 1rem;
    }
  }
  &__count {
    &__container {
      padding: 0 1rem;
      margin-top: 32px;
      gap: 1rem;
      display: flex;
      flex-direction: column;
    }
    &__title {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0.625rem;
    }
    &__info {
      letter-spacing: 0.25px;
      line-height: 20px;
      margin: 0 0 0.625rem 0;
      color: #586983;
      font-family: ConnectLigera, sans-serif;
      strong {
        font-family: ConnectNegrita, sans-serif;
      }
    }
  }
  &__divider__container {
    height: 2px;
    width: 100%;
    background-color: $elementos-ui-azul-05;
    margin: 1.875rem -1rem 1rem -1rem;
  }
  &__properties__container {
    margin: 1.25rem 0 0 0;
  }
  &__prices__info {
    display: flex;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.156rem;
    letter-spacing: 0.44px;
    flex-direction: column;
    margin: 0 auto 0 0.813rem;
    .plus,
    .less {
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0.25px;
    }
    .less {
      color: $alertas-error;
      &::before {
        content: '-';
        margin-right: 0.2rem;
      }
    }
    .plus {
      color: $color-successful;
      &::before {
        content: '+';
        margin-right: 0.2rem;
      }
    }
  }
  &__payment__title {
    font-size: 20px;
    line-height: 32px;
    margin: 0;
  }
  &__payment__description {
    line-height: 20px;
    letter-spacing: 0.25px;
    margin: 0;
    &.italic {
      font-style: italic;
      color: $color-text-disabled;
      margin: 0.1rem 0 1rem;
    }
  }
  &__popup {
    &__add {
      background-color: $color-background-primary;
      padding: 1.2rem 1rem 1.75rem;
      border-radius: 0.5rem;
      display: flex;
      flex-direction: column;
      .button__close,
      .button__save {
        margin-left: auto;
      }

      .subtitle {
        max-width: 14.375rem;
        margin: 0 auto 1.938rem 0;
      }
    }
  }

  &__button {
    margin-top: 1rem;
  }
}

.coupon__info__container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
  .tag__label__container {
    width: 100%;
    justify-content: space-between;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .tag__label__info__coupon {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin: 0 auto 0 0.5rem;
    font-family: ConnectRegular, sans-serif;

    &--bold {
      font-family: ConnectNegrita, sans-serif;
    }
  }
}
