@import 'assets/styles/animations';

.reset__password {
  &__container {
    padding: 1.875rem 1.875rem 0;
  }
  &__message {
    margin: 1.875rem 0 1.625rem 0;
    .bold {
      font-family: Inter-SemiBold, sans-serif;
    }
  }
  &__button {
    margin-bottom: 16.75rem;
    img {
      margin-left: 0.75rem;
    }
  }
  &__accordion {
    @include fadeIn;
  }
}
