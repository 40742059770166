@import 'assets/styles/variables';

.settings__footer {
  &__container {
    display: flex;
    align-items: center;
    border-top: 1px solid $nav-bar-blue-disabled;
    background: #f8fcff;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
  }
  &__body {
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
    .logo__connect {
      width: 7rem;
      height: 1.206rem;
    }
  }
  &__body__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.6rem;

    .logo__instagram {
      height: 0.75rem;
      width: 0.75rem;
    }
    .logo__facebook {
    }
  }
}
