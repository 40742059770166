@import 'assets/styles/animations';
@import 'assets/styles/variables';

.edit__profile {
  &__container {
    margin: 3.813rem auto 5.5rem auto;
    max-width: 26.688rem;
    padding: 0 1.25rem;
  }
  &__form {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    &__button {
      margin: 4rem 0;
    }
    &__divider {
      margin-bottom: 1rem;
    }
    .item__list__contianer {
      margin: 0 auto;
      a {
        font-family: ConnectLigeraItalica;
        color: $elementos-ui-azul-01;
      }
    }
  }
  &__fadeIn {
    @include fadeIn;
  }
}
