@import 'assets/styles/variables';

.form__coupon {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &.margin--small {
      margin: 0 0 2rem 0;
    }
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__info__container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .tag__label__container {
      width: 100%;
      justify-content: space-between;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .tag__label__info__coupon {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      margin: 0 auto 0 0.5rem;
      font-family: ConnectRegular, sans-serif;

      &--bold {
        font-family: ConnectNegrita, sans-serif;
      }
    }
    .tag__label__icon {
      width: 20px;
      height: 20px;
    }
  }
  &__content {
    display: flex;
    gap: 1rem;
    .w-70 {
      width: 70%;
    }
    .button__coupon {
      height: auto;
      width: 30%;
    }
    .container__input {
      margin-bottom: 0;
      .input__generic {
        margin-bottom: 0;
      }
    }
  }
}
