.popup__error__recover__pasword {
  display: flex;
  justify-content: center;
  &--error {
    text-align: center;
    max-width: 220px;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: 1.25rem;
  }
  &--bold {
    font-weight: 700;
    font-family: Inter-Bold, sans-serif;
  }
}
