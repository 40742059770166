@import 'assets/styles/variables';

.payment__confirmation {
  &__header {
    background-color: $elementos-ui-naranja-01;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2.684rem 1.875rem 2.188rem 1.875rem;
    h1 {
      color: $light-component-background-01;
      font-family: ConnectNegra, sans-serif;
      font-size: 2.25rem;
      line-height: 2.5rem;
      letter-spacing: 0.0625rem;
      text-transform: uppercase;
    }
    h4 {
      color: $light-component-background-01;
      margin: 3rem 0 1rem 0;
      font-family: ConnectNegrita;
      font-size: 1.125rem;
      line-height: 1.5rem;
      letter-spacing: 0.05rem;
      text-transform: uppercase;
    }
    p {
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.05rem;
      margin: 1rem 0 0 0;
      color: $light-component-background-01;
      font-family: ConnectLigera, sans-serif;
      strong {
        font-family: ConnectNegrita, sans-serif;
      }
    }
    .logo__connect {
      width: 8.38594rem;
      height: 2rem;
      margin: 0 0 0 auto;
    }
    .logo__check {
      height: 3.078rem;
      margin-top: 3rem;
      width: auto;
    }
  }
  &__body {
    padding: 2.519rem 0.938rem 0;
    .payment__confirmation__info {
      padding: 0 0.938rem;
      display: flex;
      flex-direction: column;
      p {
        font-family: ConnectLigera, sans-serif;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: 0.05rem;
      }
      &__items {
        display: flex;
        align-items: center;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: 0.05rem;
        font-family: ConnectNegrita, sans-serif;
        gap: 0.5rem;
        &.column {
          flex-direction: column;
          align-items: flex-start;
          gap: 0;
        }
        span {
          font-family: ConnectLigera, sans-serif;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }
  &__info {
    &__container__one {
      position: relative;
      overflow: hidden;
      margin: 3.66rem 0 3.66rem 0;
      padding: 1.81rem 1.5rem 1.75rem;
      background-color: $elementos-ui-naranja-01;
      box-shadow: 0px 0px 0px 0px rgba(241, 91, 43, 0.1),
        2px 3px 8px 0px rgba(241, 91, 43, 0.1),
        6px 13px 14px 0px rgba(241, 91, 43, 0.09),
        14px 29px 19px 0px rgba(241, 91, 43, 0.05),
        25px 51px 23px 0px rgba(241, 91, 43, 0.01),
        40px 79px 25px 0px rgba(241, 91, 43, 0);
      &::before {
        content: '';
        position: absolute;
        width: 19.5625rem;
        height: 9.5625rem;
        background: url('/assets/images/surfer_van.png') no-repeat 0 0;
        background-size: 100%;
        right: -117px;
        bottom: 16px;
      }
    }
    &__title {
      margin-bottom: 1.5rem;
      h1 {
        color: $color-white;
        font-family: ConnectNegra, sans-serif;
        font-size: 1.5rem;
        line-height: 2rem;
        letter-spacing: 0.08125rem;
        text-transform: uppercase;
      }
      span {
        color: $color-white;
        font-family: ConnectFina, sans-serif;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: 0.05rem;
      }
    }
    &__button {
      width: max-content;
    }
  }
  &__support {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2.31rem;
    font-family: ConnectLigera, sans-serif;
    font-size: 0.875rem;
    line-height: 1.125rem;
    letter-spacing: 0.05rem;
    &__divider {
      width: 100%;
      margin: 0 0 0.5rem 0;
      height: 0.0625rem;
      background-color: $accent-blue-02;
    }
    span {
      margin-bottom: 0.5rem;
    }
  }
  &__plans__info {
    display: flex;
    flex-direction: column;
    a {
      font-family: ConnectLigeraItalica, sans-serif;
      color: $elementos-ui-naranja-01;
    }
  }
}
