@import 'assets/styles/variables';
@import 'assets/styles/animations';

.button__more {
  &__container {
    position: relative;
  }
  &__btn {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    margin: 0;
    outline: none;
    padding: 0;
    width: 1.5rem;
    height: 1.5rem;
    img {
      &:first-child {
        margin-left: auto;
      }
      &:nth-child(2) {
        margin: 0 0.1rem;
      }
      &:last-child {
        margin-right: auto;
      }
    }
  }
  &__icon {
    height: 0.4rem;
    width: 0.4rem;
  }
  &__options {
    @include zoomIn;
    background-color: $light-component-background-01;
    border-radius: 0.5rem;
    box-shadow: 0px 5px 15px rgba(50, 50, 50, 0.15);
    overflow: hidden;
    position: absolute;
    top: 0rem;
    z-index: 2;

    .button__more__option {
      cursor: pointer;
      font-size: 0.75rem;
      min-width: 12.5rem;
      padding: 0.75rem 0.625rem;

      &:not(:last-child) {
        border-bottom: 1px solid $light-grey;
      }
      &.font--bold {
        font-family: Inter-Bold, sans-serif;
      }

      &:hover {
        background-color: $light-grey;
      }
    }
  }
}
