@import 'assets/styles/variables';

.detail__accordion__container {
  overflow: hidden;
  padding: 1rem;
  color: $color-white;
  &.float {
    position: sticky;
    top: 3.75rem;
    z-index: 1;
  }
  &.successful {
    background-color: $alertas-exito;
  }
  &.error,
  &.informative {
    background-color: $alertas-error;
  }
  &.warning {
    background-color: $alertas-advertencia;
  }
  .detail__accordion__container__header {
    .detail__accordion__container__title {
      display: flex;
      align-items: center;
    }
    display: flex;
    justify-content: space-between;
    h1 {
      font-family: ConnectNegrita, sans-serif;
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1rem;
      letter-spacing: 0.0125rem;
      text-transform: uppercase;
      color: $color-white;
    }
    .custom {
      font-family: ConnectLigera, sans-serif;
      font-weight: 100;
      font-size: 0.875rem;
      strong {
        font-family: ConnectNegrita, sans-serif;
        font-weight: 500;
      }
    }
    small {
      font-size: 0.75rem;
    }
  }
  .detail__accordion__icon {
    width: 1.47725rem;
    height: 1.47725rem;
    margin-right: 0.5rem;
  }
  .detail__accordion__btn {
    align-items: center;
    color: $color-white;
    cursor: pointer;
    display: flex;
    font-family: ConnectRegular;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
    text-transform: uppercase;

    img {
      height: 0.75rem;
      margin-left: 0.563rem;
      width: 0.688rem;
      transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      &.rotar {
        transform: rotate(180deg);
      }
    }
  }
  .detail__accordion__content {
    color: $light-high-contrast;
    height: 0;
    letter-spacing: 0.25px;
    line-height: 20px;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;

    .detail__accordion__text {
      color: $color-white;
      padding: 1rem 0 0 0rem;
      font-family: ConnectFina, sans-serif;
      strong {
        font-family: ConnectNegrita, sans-serif;
      }
    }
  }
}
