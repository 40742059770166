@import 'assets/styles/variables';

.settings__container {
  padding: 0;
  background-color: #f8fcff;
  margin-bottom: 3rem;
}

.anchor__link {
  display: none;
  height: 0;
  width: 0;
  z-index: 0;
}
