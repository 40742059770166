@import 'assets/styles/variables';
@import 'assets/styles/animations';

.cart__checkout__skeleton {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 3rem;
    @include fadeIn;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__content {
      background: $bg-skeleton;
      border: 2px solid #eef7ff;
      padding: 1.5rem 1rem;
      .title {
        margin-bottom: 1.5rem;
        height: 1rem;
        width: 8rem;
        box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
      }
      .periodicity {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.75rem 0.8125rem 0.75rem 1rem;
        margin-bottom: 1rem;
        .radio__button {
          display: flex;
          align-items: center;
          .radio {
            height: 1.25rem;
            width: 1.25rem;
            border-radius: 50%;
            margin-right: 0.4rem;
            box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
          }
          .label {
            height: 1.5rem;
            width: 8rem;
            box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
          }
        }
        .value {
          width: 3rem;
          height: 1.5rem;
          box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
        }
      }
    }
  }
}
