.sub-details-header {
  margin-bottom: 2rem;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  
  &__title {
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &__subtitle {
    color: #777;
    font-size: 1rem;
  }
}