@import 'assets/styles/mixin-base';

.grid {
  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;

  &.rg-0 {
    row-gap: 0;
  }

  &.cg-0 {
    column-gap: 0;
  }
}

.grid__col-1 { grid-template-columns: repeat(1, 1fr); }
.grid__col-2 { grid-template-columns: repeat(2, 1fr); }
.grid__col-3 { grid-template-columns: repeat(3, 1fr); }
.grid__col-4 { grid-template-columns: repeat(4, 1fr); }
.grid__col-5 { grid-template-columns: repeat(5, 1fr); }
.grid__col-6 { grid-template-columns: repeat(6, 1fr); }
.grid__col-7 { grid-template-columns: repeat(7, 1fr); }
.grid__col-8 { grid-template-columns: repeat(8, 1fr); }
.grid__col-9 { grid-template-columns: repeat(9, 1fr); }
.grid__col-10 { grid-template-columns: repeat(10, 1fr); }
.grid__col-11 { grid-template-columns: repeat(11, 1fr); }
.grid__col-12 { grid-template-columns: repeat(12, 1fr); }

.grid__span-1 { grid-column: span 1; }
.grid__span-2 { grid-column: span 2; }
.grid__span-3 { grid-column: span 3; }
.grid__span-4 { grid-column: span 4; }
.grid__span-5 { grid-column: span 5; }
.grid__span-6 { grid-column: span 6; }
.grid__span-7 { grid-column: span 7; }
.grid__span-8 { grid-column: span 8; }
.grid__span-9 { grid-column: span 9; }
.grid__span-10 { grid-column: span 10; }
.grid__span-11 { grid-column: span 11; }
.grid__span-12 { grid-column: span 12; }

@include for-tablet {
  .grid__md-col-1 { grid-template-columns: repeat(1, 1fr); }
  .grid__md-col-2 { grid-template-columns: repeat(2, 1fr); }
  .grid__md-col-3 { grid-template-columns: repeat(3, 1fr); }
  .grid__md-col-4 { grid-template-columns: repeat(4, 1fr); }
  .grid__md-col-5 { grid-template-columns: repeat(5, 1fr); }
  .grid__md-col-6 { grid-template-columns: repeat(6, 1fr); }
  .grid__md-col-7 { grid-template-columns: repeat(7, 1fr); }
  .grid__md-col-8 { grid-template-columns: repeat(8, 1fr); }
  .grid__md-col-9 { grid-template-columns: repeat(9, 1fr); }
  .grid__md-col-10 { grid-template-columns: repeat(10, 1fr); }
  .grid__md-col-11 { grid-template-columns: repeat(11, 1fr); }
  .grid__md-col-12 { grid-template-columns: repeat(12, 1fr); }

  .grid__md-span-1 { grid-column: span 1; }
  .grid__md-span-2 { grid-column: span 2; }
  .grid__md-span-3 { grid-column: span 3; }
  .grid__md-span-4 { grid-column: span 4; }
  .grid__md-span-5 { grid-column: span 5; }
  .grid__md-span-6 { grid-column: span 6; }
  .grid__md-span-7 { grid-column: span 7; }
  .grid__md-span-8 { grid-column: span 8; }
  .grid__md-span-9 { grid-column: span 9; }
  .grid__md-span-10 { grid-column: span 10; }
  .grid__md-span-11 { grid-column: span 11; }
  .grid__md-span-12 { grid-column: span 12; }
}

@include for-desktop {
  .grid__lg-col-1 { grid-template-columns: repeat(1, 1fr); }
  .grid__lg-col-2 { grid-template-columns: repeat(2, 1fr); }
  .grid__lg-col-3 { grid-template-columns: repeat(3, 1fr); }
  .grid__lg-col-4 { grid-template-columns: repeat(4, 1fr); }
  .grid__lg-col-5 { grid-template-columns: repeat(5, 1fr); }
  .grid__lg-col-6 { grid-template-columns: repeat(6, 1fr); }
  .grid__lg-col-7 { grid-template-columns: repeat(7, 1fr); }
  .grid__lg-col-8 { grid-template-columns: repeat(8, 1fr); }
  .grid__lg-col-9 { grid-template-columns: repeat(9, 1fr); }
  .grid__lg-col-10 { grid-template-columns: repeat(10, 1fr); }
  .grid__lg-col-11 { grid-template-columns: repeat(11, 1fr); }
  .grid__lg-col-12 { grid-template-columns: repeat(12, 1fr); }

  .grid__lg-span-1 { grid-column: span 1; }
  .grid__lg-span-2 { grid-column: span 2; }
  .grid__lg-span-3 { grid-column: span 3; }
  .grid__lg-span-4 { grid-column: span 4; }
  .grid__lg-span-5 { grid-column: span 5; }
  .grid__lg-span-6 { grid-column: span 6; }
  .grid__lg-span-7 { grid-column: span 7; }
  .grid__lg-span-8 { grid-column: span 8; }
  .grid__lg-span-9 { grid-column: span 9; }
  .grid__lg-span-10 { grid-column: span 10; }
  .grid__lg-span-11 { grid-column: span 11; }
  .grid__lg-span-12 { grid-column: span 12; }
}

@include for-desktop-small {
  .grid__only-col-1 { grid-template-columns: repeat(1, 1fr); }
  .grid__only-col-2 { grid-template-columns: repeat(2, 1fr); }
  .grid__only-col-3 { grid-template-columns: repeat(3, 1fr); }
  .grid__only-col-4 { grid-template-columns: repeat(4, 1fr); }
  .grid__only-col-5 { grid-template-columns: repeat(5, 1fr); }
  .grid__only-col-6 { grid-template-columns: repeat(6, 1fr); }
  .grid__only-col-7 { grid-template-columns: repeat(7, 1fr); }
  .grid__only-col-8 { grid-template-columns: repeat(8, 1fr); }
  .grid__only-col-9 { grid-template-columns: repeat(9, 1fr); }
  .grid__only-col-10 { grid-template-columns: repeat(10, 1fr); }
  .grid__only-col-11 { grid-template-columns: repeat(11, 1fr); }
  .grid__only-col-12 { grid-template-columns: repeat(12, 1fr); }

  .grid__only-span-1 { grid-column: span 1; }
  .grid__only-span-2 { grid-column: span 2; }
  .grid__only-span-3 { grid-column: span 3; }
  .grid__only-span-4 { grid-column: span 4; }
  .grid__only-span-5 { grid-column: span 5; }
  .grid__only-span-6 { grid-column: span 6; }
  .grid__only-span-7 { grid-column: span 7; }
  .grid__only-span-8 { grid-column: span 8; }
  .grid__only-span-9 { grid-column: span 9; }
  .grid__only-span-10 { grid-column: span 10; }
  .grid__only-span-11 { grid-column: span 11; }
  .grid__only-span-12 { grid-column: span 12; }
}
