@import 'assets/styles/variables';
.alert__contianer {
  display: flex;
  flex-direction: column;
  animation: fadeOut 17s linear forwards;
  .alert__content {
    display: flex;
    flex-direction: column;
    padding: 0.938rem 1.375rem 0 0.875rem;
  }
  .alert__container__header {
    display: flex;
    justify-content: space-between;

    .alert__title {
      color: $color-successful;
      font-family: Inter-Bold, sans-serif;
      font-size: 0.875rem;
      letter-spacing: 0.75px;
      line-height: 1.063rem;
      text-transform: uppercase;
    }
  }
  .alert__text {
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin: 0.313rem 0 0.946rem;
  }
  &.successful {
    background-color: $bg-successful;
  }
  &.error {
    background-color: $bg-error;
  }
  &.warning {
    background-color: $bg-warning;
  }
  .alert__bar {
    height: 0.444rem;
    background-color: $accent-blue-01;
    width: 100%;
    &__progress {
      background-color: $color-successful;
      height: 100%;
      width: 0%;
      animation: runProgress 15s linear forwards 0.5s;
    }
  }
}

@keyframes runProgress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
    transform: translateY(0px);
  }
  99% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 0;
  }
}
