@import 'assets/styles/variables';

.date__picker {
  position: absolute;
  top: 3.125rem;
  right: 0;
  z-index: 1;
  &__select {
    position: relative;

    .date__picker__select__container {
      padding: 0.5rem 1rem;
      border: 1px solid var(--elementos-ui-naranja-01, #f15b2b);
      background: var(--fondos-blanco, #fff);
      .date__picker__select__icon {
        width: 1.5rem;
        height: 1.6rem;
      }
    }
  }
  &__container {
    padding: 1.063rem 1.25rem;
    width: max-content;
    &__months {
      cursor: grab;
      position: relative;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      &::before {
        content: '';
        border: 0.5px solid #bebebe;
        position: absolute;
        width: 100%;
        background-color: red;
        top: 2.625rem;
      }
      &::after {
        content: '';
        border: 0.5px solid #bebebe;
        position: absolute;
        width: 100%;
        background-color: red;
        bottom: 2.625rem;
      }
    }
  }
  &__chips__container {
    display: flex;
    margin-bottom: 0.875rem;
    & > *:not(:last-child) {
      margin-right: 0.313rem;
    }
  }
  &__buttons__container {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.75rem;
    :first-child {
      margin-right: 0.375rem;
    }

    .button__small {
      padding: 0.5rem 0.875rem;
      font-weight: 100;
      background-color: $color-primary-dark;
      &.clear {
        background-color: $color-disabled-secondary;
        color: #b7b7b7;
      }
    }
  }
  &__content {
    display: flex;
    &__info {
      display: flex;
      width: 14.875rem;
      flex-wrap: wrap;

      .date__picker {
        &__item {
          align-items: center;
          display: flex;
          font-family: Inter-Medium, sans-serif;
          font-size: 0.625rem;
          justify-content: center;
          color: $color-text-black;
          border-radius: 0.563rem;
          height: 2.125rem;
          width: 2.125rem;
        }
        &__day {
          cursor: pointer;
          font-size: 0.75rem;
          line-height: 0.75rem;
          font-family: Inter-Light, sans-serif;
          font-weight: 100;
          height: 1.5rem;
          padding: 5px;
          margin: 0.313rem;
          width: 1.5rem;

          &:hover:not(.selected, .selected--range, .disabled) {
            background-color: $color-primary-dark;
            color: $light-component-background-01;
          }
          &.another__month {
            color: $color-text-disabled-secondary;
          }
          &.selected {
            background-color: $color-primary-dark;
            color: $light-component-background-01;
            &--range {
              background-color: $color-disabled-secondary;
              color: $color-text-disabled-secondary;
            }
          }
          &.disabled {
            color: $color-text-disabled;
            cursor: default;
          }
        }
      }
    }
    &__months {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 4.375rem;
      background: linear-gradient(
        180deg,
        rgba(248, 248, 248, 0.2) 17.91%,
        #f8f8f8 49.88%,
        rgba(248, 248, 248, 0.2) 81.86%
      );
      .container__swiper {
        height: 7.125rem;
        width: 3.125rem;
      }
      .date__picker__controls {
        margin-top: 1.25rem;
        margin-bottom: 1.5rem;
        .date__picker__arrow {
          cursor: pointer;
          height: 1.25rem;
          padding: 0.25rem;
          width: 1.25rem;
          &--rotated {
            transform: rotate(180deg);
          }
        }
      }
      .date__picker__month {
        align-items: center;
        color: #858585;
        display: flex;
        font-size: 0.625rem;
        justify-content: center;
        &.selected {
          color: $color-text-black;
          font-size: 0.875rem;
          font-weight: 600;
          position: relative;
        }
        &.next {
          font-size: 0.75rem;
          color: #5a5a5a;
        }
      }
    }
  }
}
