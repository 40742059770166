@import 'assets/styles/variables';
.user__info {
  &__container {
    display: flex;
  }
  &__data {
    display: flex;
    flex-direction: column;
    margin: 0 auto 0 0;
    max-width: 100%;
    word-wrap: break-word;

    &__name {
      color: $light-high-contrast;
    }
    &__country,
    &__email,
    &__phone {
      color: $light-medium-contrast;
      font-family: Inter-Light, sans-serif;
      font-size: 0.875rem;
      letter-spacing: 0.25px;
      line-height: 1.25rem;
    }
    &__country {
      font-family: Inter-SemiBold, sans-serif;
    }
  }
  &__edit__info {
    cursor: pointer;
    height: 1.221rem;
    margin: auto 0 1.296rem 0;
    width: 1.221rem;
  }
}
