.item__payment__container {
  padding: 0.688rem 0.938rem 0.938rem;
  .item__payment__actions {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.875rem;
    .item__payment__logo {
      width: 3.125rem;
      height: 1rem;
    }
  }
  .item__payment__title {
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    letter-spacing: 0.44px;
    line-height: 1.125rem;
    margin-bottom: 0.313rem;
  }
  .item__payment__chips {
    margin-bottom: 0.313rem;
  }
}
