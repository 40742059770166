@import 'assets/styles/variables';

.popup__exist_porcess {
  align-items: center;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  padding: 2.031rem 1.438rem 3.875rem 2.063rem;
  align-items: flex-start;
  &.error {
    background-color: $bg-error;
  }
  &.warning {
    background-color: $color-text-warning;
  }
  &.successful {
    background-color: $bg-successful;
  }
  &__close {
    margin-left: auto;
  }
  &__icon {
    margin-bottom: 0.938rem;
    height: 3.141rem;
    width: 3.472rem;
  }
  &__text {
    letter-spacing: 0.25px;
    line-height: 20px;
    margin: 0 auto 0.938rem 0;
    text-align: left;
    max-width: 14.375rem;
  }
  &__container__btns {
    display: flex;
    justify-content: flex-start;
    margin-right: auto;
  }
  &__btn {
    border: none;
    cursor: pointer;
    font-size: 0.875rem;
    letter-spacing: 0.75px;
    outline: none;
    padding: 0.5rem 1rem;

    &.warning {
      color: $color-text-warning;
      background-color: $bg-warning;
      &.ghost {
        color: $alertas-advertencia-claro;
        border: 1px solid $alertas-advertencia-claro;
        background-color: transparent;
        margin-right: 0.625rem;
      }
    }
    &.successful {
      color: $light-component-background-01;
      background-color: $color-successful;
      &.ghost {
        color: $color-successful;
        border: 1px solid $color-successful;
        background-color: transparent;
        margin-right: 0.625rem;
      }
    }
    &.error {
      color: $light-component-background-01;
      background-color: $alertas-error;
      &.ghost {
        color: $alertas-error;
        border: 1px solid $alertas-error;
        background-color: transparent;
        margin-right: 0.625rem;
      }
    }
    &.disabled {
      background-color: #e5e5e5;
      cursor: default;
    }
  }
}
