@import 'assets/styles/variables';
.container__input {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  overflow: hidden;

  .input__generic {
    font-family: ConnectRegular, sans-serif;
    line-height: 1rem;
    letter-spacing: 0.05rem;
    font-size: $font-size-m;
    outline: none;
    padding: 1.44rem 1rem 0.44rem 1rem;
    margin-bottom: 0.25rem;
    width: 100%;
    border: 1px solid $elementos-ui-azul-03;
    background: $color-white;
    &.uppercase {
      text-transform: uppercase;
    }
    &.error {
      border: 1px solid $alertas-error;
      background: $alertas-error-claro;
    }
    &.prefix {
      padding: 1.44rem 1rem 0.44rem 1.5rem;
      &--medium {
        padding-left: 2.2rem;
      }
      &--large {
        padding-left: 3.5rem;
      }
    }
    &__prefix {
      display: none;
      &.block {
        display: block;
      }
    }

    &:focus {
      &:not(:disabled, .error) {
        border: 1px solid $alertas-info;
        caret-color: $alertas-info;
      }

      + .input__label,
      + label {
        transform: translate(-3px, -9px) scale(0.9);
        &:not(.label--error) {
          color: $elementos-ui-azul-02;
        }
      }
      + .input__label + .input__generic__prefix {
        display: block;
      }
    }

    &.completed {
      + label {
        transform: translate(-3px, -9px) scale(0.9);
      }
    }

    &:disabled {
      border: 1px solid $elementos-ui-gris;
      background: #F5F5F5;
      color: #586983;
      pointer-events: none;
      + label {
        color: #586983;
      }
    }

    &[type='password'] {
      font-family: Fontello, sans-serif;
      font-size: 0.5rem;
    }

    &[type='date']:not(.value) {
      &::-webkit-datetime-edit-text {
        -webkit-appearance: none;
        display: none;
      }
      &::-webkit-datetime-edit-month-field {
        -webkit-appearance: none;
        display: none;
      }
      &::-webkit-datetime-edit-day-field {
        -webkit-appearance: none;
        display: none;
      }
      &::-webkit-datetime-edit-year-field {
        -webkit-appearance: none;
        display: none;
      }
    }
    &__prefix {
      position: absolute;
      left: 1rem;
      top: 1.44rem;
    }
  }
  .input__button__action {
    align-items: center;
    display: flex;
    position: absolute;
    height: 1.25rem;
    top: 1rem;
    right: 0.8rem;

    .input__button__toggle__type {
      background: none;
      border: none;
      cursor: pointer;
      margin: 0;
      outline: none;
      margin-right: 0.903rem;
      padding: 0;
    }
  }
  .input__label {
    color: #001D3D;
    font-size: $font-size-m;
    position: absolute;
    left: 1rem;
    top: 1rem;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-family: ConnectLigera, sans-serif;
    width: max-content;
    &.label--error {
      color: $alertas-error;
      img {
        filter: hue-rotate(314deg);
      }
    }
    &.input__label--value {
      transform: translate(-3px, -9px) scale(0.9);
      &:not(.label--error, .input__label--disabled) {
        color: $elementos-ui-azul-02;
      }
    }
    &.input__label--disabled {
      color: $elementos-ui-gris;
      .input__image__required {
        filter: grayscale(1) opacity(1);
      }
    }
    img {
      margin-left: 0.25rem;
      width: 6px;
    }
  }
  .input__error {
    font-family: ConnectRegular, sans-serif;
    color: $alertas-error;
    text-align: left;
    font-size: 12px;
    line-height: 1rem;
    letter-spacing: 0.5px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: 1000s ease-in-out 0s;
}
