@import 'assets/styles/variables';

.container {
  &__btn__provider {
    border-radius: 0.5rem;
    color: $dark-color-background;
    cursor: pointer;
    display: flex;
    font-family: Inter-Bold, sans-serif;
    height: 2.5rem;
    justify-content: center;
    margin: auto;
    width: 100%;

    .label {
      align-self: center;
    }

    &.google {
      background-color: $light-grey;

      &:hover {
        box-shadow: 0 0 3px 3px rgba(219, 223, 230, 0.3);
      }
    }

    &.facebook {
      background-color: #165fbb;

      &:hover {
        box-shadow: 0 0 3px 3px rgb(5 65 164 / 30%);
      }
    }

    &.disabled {
      background-color: $color-primary-disabled;
      pointer-events: none;
    }
  }

  &__logo__provider {
    align-items: center;
    display: flex;

    justify-content: center;
    margin: 0 1.188rem 0 0;

    span {
      align-self: center;
      height: 1.25rem;
      width: 1.25rem;
    }
  }
}
