@import 'assets/styles/variables';

.popUp__welcome {
  &__contianer {
    background-color: $elementos-ui-azul-01;
    border-radius: 0.625rem;
    padding: 1.563rem 1.188rem 1.875rem 1.188rem;
    position: relative;
    text-align: center;
    overflow: hidden;
  }
  &__background__header {
    height: 22.625rem;
    left: -2.625rem;
    right: -2.625rem;
    top: -3.8rem;
    border-radius: 0 0 50% 50%;
    background-color: $light-component-background-01;
    position: absolute;
  }
  &__btn__close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 1;
  }
  &__message {
    letter-spacing: 0.25px;
    line-height: 20px;
    margin: 0;
  }
}

.carousel {
  &__container {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
  }

  &__content {
    display: flex;
    scroll-snap-type: x mandatory;
    overflow-x: hidden;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__item {
    color: $light-component-background-01;
    width: 100%;
    flex-shrink: 0;
    scroll-snap-align: start;
    &__text {
      text-align: left;
      margin: 2.6rem auto 1.25rem auto;
      line-height: 20px;
      letter-spacing: 0.25px;
      max-width: 16.313rem;
      &.center {
        text-align: center;
      }
    }
    img {
      max-width: 237px;
      margin: 0 auto;
      height: 15.563rem;
    }

    ul {
      text-align: left;
      width: max-content;
      max-width: 16.313rem;
      margin: 0 auto;
      padding: 0 1rem;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    .carousel__image {
      display: block;
    }

    .carousel__title {
      color: $light-component-background-01;
      margin: 2.6rem 0 1.25rem 0;
    }
  }
  &__controls {
    .controls {
      &__dots {
        .controls__dot {
          display: inline-block;
          width: 0.85em;
          height: 0.85em;
          border-radius: 50%;
          outline: none;
          background-color: $light-component-background-01;
          opacity: 0.8;
          cursor: pointer;
          transition: opacity 0.2s;

          &:not(:first-child) {
            margin-left: 0.625rem;
          }

          &:hover,
          &:focus {
            opacity: 1;
          }

          span {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
          }
        }
      }
      &__arrow {
        display: flex;
        justify-content: space-between;
        padding: 0 1.875rem;
        margin-left: auto;
        &--left {
          transform: rotate(180deg);
        }
        &--right {
          margin-left: auto;
        }
      }
    }
  }
}
