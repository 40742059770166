@import 'assets/styles/variables';

.edit__plan__skeleton {
  &__container {
    padding: 0 1.875rem;
  }
  &__title {
    height: 1rem;
    margin-bottom: 0.25rem;
    width: 60%;
    box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
  }
  &__sub__title {
    height: 1rem;
    margin-bottom: 0.25rem;
    width: 50%;
    box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
  }

  &__count__title {
    width: 30%;
    height: 2rem;
    margin-bottom: 0.625rem;
  }
  &__count__sub__title {
    width: 70%;
    height: 20px;
    margin: 0 0 0.625rem 0;
  }
  &__prices__container {
    display: flex;
    justify-content: space-between;
    .amount__title {
      height: 0.875rem;
      width: 80px;
      margin-bottom: 0.3rem;
    }

    .amount__price {
      height: 1rem;
      width: 40px;
    }
  }
}
