.payment__method__stripe__container {
  &__checkbox {
    width: max-content;
    margin: 1.25rem auto 0 0;
  }
  &__elements {
    display: flex;
    gap: 1rem;
  }  .container__button__delete {
    position: absolute;
    top: 1rem;
    right: 0.8rem;
  }
}
