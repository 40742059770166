@import 'assets/styles/variables';

.buy__plan__container {
  text-align: left;
  padding: 1.56rem 1rem 0 1rem;
}

.payment__margin {
  margin: 1.875rem 0;
}

.content__payment {
  .payment__title {
    margin-top: 1.875rem;
  }
  .payment__description {
    margin: 0 0 0.625rem;
    text-align: left;
    line-height: 1.25rem;
  }
  .container__elements__stripe {
    display: flex;
  }

  .container__element__stripe {
    &.small {
      width: 7.4rem;
      margin-left: 0.563rem;
    }
    .container__button__delete {
      position: absolute;
      top: 1rem;
      right: 0.8rem;
    }
  }
  .sumary__totlas__divider {
    margin: 1rem 0 1.563rem 0;
    padding: 0 0.938rem;
  }
}
.payment__padding {
  padding: 0 1.875rem;
}

.StripeElement--focus {
  ~ .input__label {
    transform: translate(-3px, -9px) scale(0.9);
  }
}

#payment-information-coupon {
  text-transform: uppercase;
}
