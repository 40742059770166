@import 'assets/styles/variables';
@import 'assets/styles/animations';

.purchase__order__title {
  margin-bottom: 1.5rem;
}

.cart__checkout {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @include fadeIn;
    &__title {
      margin-bottom: 24px;
    }
  }
  &__message__trial {
    color: $elementos-ui-azul-01;
    text-align: center;
    font-family: ConnectLigera, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: 18px;
    letter-spacing: 0.8px;
    margin: 0 0 39px 0;
  }
}
