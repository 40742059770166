@import 'assets/styles/variables';

.upload {
  &__container {
    display: flex;
    height: 3rem;
    cursor: pointer;
    label {
      input {
        display: none;
      }
    }
  }

  &__icon__close {
    position: absolute;
    top: 1rem;
    right: 0.8rem;
  }

  &__label {
    &__texts {
      position: relative;
    }
    &__placeholder {
      color: $elementos-ui-azul-01;
      font-size: 0.875rem;
      position: absolute;
      left: 1rem;
      top: 1rem;
      transition: all 0.3s ease;
      font-family: ConnectLigera, sans-serif;
      &.disabled {
        color: $elementos-ui-azul-03;
        font-size: 0.75rem;
        top: 0.375rem;
      }

      &.error {
        color: $alertas-error;
      }
    }
    &__name__file {
      display: flex;
      width: 100%;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 1rem;
    }
  }
  &__icon {
    display: flex;
    height: 1.25rem;
    width: 1.25rem;
    position: absolute;
    top: 1rem;
    right: 0.8rem;
  }
}