:root {
  --animate-delay: 1.5s;
  --animate-duration: 1.5s;
}

@mixin animated-skeleton {
  -webkit-animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

@keyframes skeleton {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@keyframes skeleton {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@mixin skeleton {
  -webkit-animation: 1.4s infinite skeleton;
  animation: 1.4s infinite skeleton;
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;

  @include animated-skeleton;

  &.shadow {
    box-shadow: 0 1px 3px 0 rgb(33 33 33 / 14%);
  }
}
