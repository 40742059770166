@import 'assets/styles/animations';
.popup__drop__off__overload {
  align-items: center;
  backdrop-filter: blur(7px);
  background: #001d3dd9;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  top: 56px;
  z-index: 1;

  .popup__drop__off__container {
    margin: 0 1.438rem;
    max-width: 20.688rem;
    width: 100%;
    background-color: #f8fcff;
    padding: 24px 24px 40px 24px;

    @include zoomIn;

    .popup__drop__off__close {
      display: flex;
      justify-content: flex-end;

      .popup__drop__off__button {
        width: max-content;
        height: max-content;
      }
    }
    .popup__drop__off__body {
      display: flex;
      gap: 24px;
      flex-direction: column;
      .popup__drop__off__icon {
        width: 60px;
        height: 60px;
        aspect-ratio: 1/1;
      }

      .popup__drop__off__header {
        display: flex;
        flex-direction: column;
        gap: 8px;
        &__title {
          font-size: 36px;
          letter-spacing: 1px;
          font-family: ConnectNegra, sans-serif;
        }
        &__subtitle {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.2px;
          font-family: ConnectNegrita, sans-serif;
          text-transform: uppercase;
        }
        &__text {
          font-family: ConnectLigera, sans-serif;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.8px;
          font-weight: 200;
          margin: 0;
        }
      }
      .popup__drop__off__options {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .popup__drop__off__option {
          background-color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px 8px 16px 16px;
          box-shadow: 4px 3px 3px 0px #001d3d0d;
          text-transform: uppercase;
          cursor: pointer;
          &.selected {
            color: #f15b2b;
            box-shadow: 2px 1px 2px 0px #f15b2b17;
          }
        }
      }

      .popup__drop__off__button {
        display: flex;
        justify-content: flex-end;
        .button {
          margin-bottom: 0;
          min-width: fit-content;
          width: fit-content;
        }
      }

      .popup__drop__off__divider {
        height: 1px;
        width: 100%;
        background-color: #a3bed6;
      }
      .popup__drop__off__footer {
        display: flex;
        gap: 16px;
        flex-direction: column;
        p {
          font-family: ConnectLigera, sans-serif;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.8px;
          margin: 0;
        }
        &__button {
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: center;
          padding: 12px 24px 12px 24px;
          border: 1px solid #001d3d;
          background-color: transparent;
          outline: none;
          font-family: ConnectNegrita, sans-serif;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.8px;
          text-transform: uppercase;
          cursor: pointer;
          color: #001d3d;
          text-decoration: none;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
