@import 'assets/styles/variables';

.tag__status__container {
  font-family: Inter-Medium, sans-serif;
  font-size: 0.75rem;
  height: 1.313rem;
  min-width: 3.875rem;
  padding: 0 0.5rem;
  text-align: center;
  width: max-content;

  &.error {
    background-color: $bg-error;
    border-radius: 5px;
    border: 1px solid $alertas-error;
    color: $alertas-error;
  }

  &.pending {
    background-color: $bg-warning;
    border-radius: 5px;
    border: 1px solid $color-text-warning;
    color: $color-text-warning;
  }

  &.successful {
    background-color: $bg-successful;
    border-radius: 5px;
    border: 1px solid $color-successful;
    color: $color-successful;
  }
}
