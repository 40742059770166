@import './src/assets/styles/variables';
@import './src/assets/styles/mixin-elements';
@import './src/assets/styles/system-grid';
@import './src/assets/styles/fonts';

body {
  margin: 0;
  color: $elementos-ui-azul-01;
  background-color: $fondos-celeste;
  font-family: ConnectRegular, Arial, Helvetica, sans-serif;
  font-size: $font-size-m;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  &.modal__open {
    overflow: hidden;
  }
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.grecaptcha-badge {
  visibility: hidden;
}