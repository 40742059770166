.menu__list {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &__icon {
    width: 2rem;
    height: 2rem;
  }
}
