@import 'assets/styles/variables';
.generic__error {
  &__container {
    padding: 3.5rem 0 0 0;
    .card__info__container {
      width: 100%;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
  }
  &__icon {
    height: 3.75rem;
    width: 3.75rem;
  }
  &__title {
    margin: 3rem 0 1rem 0;
    text-align: center;
  }
  &__message {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 200;
    line-height: 16px;
    letter-spacing: 0.8px;
    margin: 0 0 3rem 0;
  }
  &__button {
    min-width: 11.938rem;
    width: max-content;
    margin: 0 0 5rem 0;
  }
}
