@import 'assets/styles/variables';
@import 'assets/styles/mixin-base';

.forgot__password {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    margin-bottom: 3.5rem;
  }
  &__content {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    &__image--rotate {
      transform: rotate(90deg);
    }
    &__body {
      padding: 0 24px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      &__content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        &__text {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          p {
            margin: 0;
            font-size: 1rem;
            line-height: 1.5rem;
            letter-spacing: 0.800000011920929px;
            text-align: left;
          }
          .body__content__text__title {
            font-family: ConnectNegrita;
            font-weight: 700;
          }
          .body__content__text__detail {
            font-family: ConnectLigera;
            font-weight: 200;
          }
        }
      }
      .forgot__password__input {
        @include for-other-device {
          max-width: 280px;
        }
      }
      .forgot__password__button {
        width: max-content;
      }
    }
    &__aside {
      width: 100%;
    }
  }
}
