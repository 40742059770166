$color-white: rgba(255, 255, 255, 1);
$elementos-ui-azul-01: #001d3d;
$elementos-ui-azul-03: #a3bed6;
$elementos-ui-azul-02: #586983;
$elementos-ui-azul-05: #eef7ff;
$elementos-ui-naranja-01: #f15b2b;
$elementos-ui-naranja-03: #f8a78d;
$elementos-ui-naranja-04: #fcccbd;
$elementos-ui-naranja-05: #fff2ee;
$alertas-error: #f44336;
$alertas-error-claro: #fff6f6;
$alertas-info: #2979ff;
$marca-naranja-connect: #f15b2b;
$nav-bar-blue-progress: #178df6;
$nav-bar-blue-disabled: #cadae8;
$elementos-ui-gris: #e5e5e5;
$fondos-celeste: #f9fcff;
$fondos-naranjas: #fffaf9;
$alertas-exito-claro: #ecf8e8;
$alertas-exito: #43a047;
$alertas-advertencia: #ffb41f;
$alertas-advertencia-claro: #fff8ea;

$color-primary: #F15B2B;
$color-primary-dark: #bc5f1c;
$color-primary-action: #e87722;
$color-primary-disabled: #bfcdd2;
$color-background-primary: #f8fafb;
$dark-color-background: #111b22;
$color-text-primary: #515151;
$color-tap: #ebf2ff;
$bg-error: #fddcda;
$bg-skeleton: #e4e4e4;
$color-successful: #43a047;
$bg-successful: #dff0d8;
$color-disabled: #c4c4c4;
$color-disabled-secondary: #f5f6f8;
$color-azul-02: #4799b4;
$color-text-disabled: #7d92a1;
$color-text-disabled-secondary: #b7b7b7;
$color-text-warning: #ffb41f;
$color-text-black: #000000;
$bg-warning: #fff8ea;
$font-size-xs: 0.625rem;
$font-size-s: 0.75rem;
$font-size-m: 0.875rem;
$font-size: 1rem;
$font-size-l: 1.25rem;
$font-size-xl: 1.4rem;
$font-size-xxl: 1.813rem;

$accent-blue-01: #c3dfeb;
$accent-blue-02: #b9d3de;
$accent-blue-03: #a0c0cd;
$accent-blue-04: #7fadc0;
$accent-orange-01: #ba5f1a;
$accent-orange-02: #e96f24;
$accent-orange-03: #ffa562;
$accent-orange-04: #bb5f1b;
$accent-orange-05: #ffe4cf;
$light-grey: #ebf1f5;
$light-high-contrast: #324552;
$light-medium-contrast: #566976;
$dark-medium-contrast: #acbac3;
$light-component-background-01: #fff;
$gray-100: #19191d;
