@import 'assets/styles/variables';

.popup__informative_custom_service {
  &__container {
    align-items: center;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.438rem 2.75rem 1.438rem;
    min-height: 17.375rem;
    background-color: #f8fcff;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }
  &--error {
    background-color: $bg-error;
  }
  &--warning {
    background-color: $bg-warning;
  }
  &--successful {
    background-color: $bg-successful;
  }
  &__close {
    margin: 0.438rem 0.438rem 0.438rem auto;
  }
  &__icon {
    height: 2.708rem;
    margin: 0.521rem;
    width: 2.708rem;
  }
  &__title {
    font-family: 'ConnectNegra';
    font-size: 24px;
    line-height: 24px;
    margin: 1.25rem 0 0.438rem 0;
  }
}
