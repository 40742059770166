@import 'assets/styles/variables';

.container__button {
  background: $light-component-background-01;
  bottom: 0;
  box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: flex-end;
  left: 0;
  padding: 0.591rem 1.313rem 0.659rem 1.313rem;
  position: fixed;
  right: 0;
}
