@import 'assets/styles/variables';

.item__status {
  align-items: center;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 3px 10px 5px 10px;
  width: fit-content;
  min-width: 5.188rem;
  justify-content: center;
  &__text {
    font-family: ConnectRegular;
    font-size: 0.75rem;
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
    margin: 0;
  }
  &.activo,
  &.pending,
  &.cancelled {
    background: $bg-warning;
    color: $color-text-warning;
  }
  &.finalizado,
  &.successful {
    background: $bg-successful;
    color: $color-successful;
  }
  &.cancelado,
  &.error {
    background: $bg-error;
    color: $alertas-error;
  }
  &.big {
    padding: 0.438rem 1.25rem;
  }
  &__icon {
    height: auto;
    width: 0.5rem;
    margin-right: 0.25rem;
  }
}
