@import 'assets/styles/variables';

.container__link {
  align-items: center;
  display: flex;
  flex-direction: column;

  :first-child {
    margin-bottom: 0.5rem;
  }
}
