@import 'assets/styles/variables';

.button__delete {
  border-radius: 50%;
  border: none;
  cursor: pointer;
  height: 1.25rem;
  margin: auto;
  padding: 0;
  outline: none;
  width: 1.25rem;
  &--disabled {
    pointer-events: none;
    cursor: none;
    svg {
      rect {
        fill: $elementos-ui-gris;
      }
    }
  }
}
