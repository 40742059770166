@import 'assets/styles/variables';
.container__subtitle__section {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .container__title {
    align-items: flex-end;
    display: flex;
    min-height: 32px;

    .title__section {
      font-family: ConnectNegra, Prompt;
      font-size: 18px;
      text-transform: uppercase;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.8px;
      margin: 0;
    }
  }
  .subtitle {
    margin: 0 0 1rem;

    &__section {
      color: $light-medium-contrast;
      font-size: 14px;
    }
    &__extra__info {
      font-size: 11px;
    }
  }
}
