@import 'assets/styles/variables';

.item__add__property__skeleton__product {
  display: flex;
  justify-content: space-between;
  padding: 0.625rem 0.704rem 0.594rem 0.97rem;
  box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
  margin-bottom: 1.5rem;
  .icon {
    box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
    border-radius: 50%;
    margin-right: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  .quantity {
    display: flex;
    align-items: center;
    .button {
      border-radius: 50%;
      box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
      height: 1.146rem;
      width: 1.146rem;
      &.less {
        margin-right: 1rem;
      }
      &.plus {
        margin-left: 1rem;
      }
    }
    .border {
      height: 100%;
      width: 1px;
      background-color: $bg-skeleton;
      box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
    }
    .value {
      box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
      height: 1.5rem;
      width: 0.5rem;
      margin: 0.4rem 1.5rem;
    }
  }
}