@font-face {
  font-family: Inter-Light;
  src: url('/assets/fonts/inter/Inter-Light.otf') format('truetype');
}

@font-face {
  font-family: Inter-Regular;
  src: url('/assets/fonts/inter/Inter-Regular.otf') format('truetype');
}

@font-face {
  font-family: Inter-Medium;
  src: url('/assets/fonts/inter/Inter-Medium.otf') format('truetype');
}

@font-face {
  font-family: Inter-Bold;
  src: url('/assets/fonts/inter/Inter-Bold.otf') format('truetype');
}

@font-face {
  font-family: Inter-SemiBold;
  src: url('/assets/fonts/inter/Inter-SemiBold.otf') format('truetype');
}

@font-face {
  font-family: Fontello;
  src: url('/assets/fonts/fontello/fontello.ttf') format('truetype');
}
@font-face {
  font-family: ConnectRegular;
  src: url('/assets/fonts/connect/Connect-Regular.otf') format('truetype');
}
@font-face {
  font-family: ConnectItalica;
  src: url('/assets/fonts/connect/Connect-RegularItalica.otf') format('truetype');
}
@font-face {
  font-family: ConnectNegrita;
  src: url('/assets/fonts/connect/Connect-Negrita.otf') format('truetype');
}
@font-face {
  font-family: ConnectNegra;
  src: url('/assets/fonts/connect/Connect-Negra.otf') format('truetype');
}

@font-face {
  font-family: ConnectLigeraItalica;
  src: url('/assets/fonts/connect/Connect-LigeraItalica.otf') format('truetype');
}
@font-face {
  font-family: ConnectLigera;
  src: url('/assets/fonts/connect/Connect-Ligera.otf') format('truetype');
}

@font-face {
  font-family: ConnectFinaItalica;
  src: url('/assets/fonts/connect/Connect-FinaItalica.otf') format('truetype');
}

@font-face {
  font-family: ConnectFina;
  src: url('/assets/fonts/connect/Connect-Fina.otf') format('truetype');
}
