@import 'assets/styles/animations';

.add__property__skeleton {
  &__container {
    @include fadeIn;
    padding: 0 0.875rem;
  }
  &__icon {
    margin-bottom: 1.25rem;
    height: 6.25rem;
    width: 6.25rem;
    box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
    border-radius: 50%
  }
  &__select{
    height: 3.125rem;
    width: 100%;
    box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
    margin-bottom: 1rem;
  }
  &__label{
    margin: 0.625rem 0 1.25rem 0;
    height: 1rem;
    width: 60%;
    box-shadow: 0 1px 4px 0 rgb(33 33 33 / 14%);
  }
}
