.popup__email__send {
  &--successful {
    max-width: 14rem;
    margin-top: 0.5rem;
    text-align: center;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: 4.4rem;
  }
  &--bold {
    font-weight: 700;
    font-family: Inter-Bold, sans-serif;
  }
}
