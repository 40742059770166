@import 'assets/styles/variables';

.summary__prices {
  &__container {
    display: flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
  }
  &__edit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    background-color: $alertas-advertencia-claro;
    padding: 0px 6px 2px 6px;
    gap: 2px;
    border-radius: 100px;
    &__icon {
      width: 12px;
      height: 10px;
    }
    span {
      font-size: 12px;
      line-height: 16px;
    }
  }
  &__divider {
    height: 1px;
    width: 100%;
    background-color: $elementos-ui-azul-03;
    margin-top: 16px;
  }

  &__label,
  &__value {
    font-size: 0.875rem;
    font-size: 1rem;
    line-height: 24px;
    font-family: ConnectLigera, sans-serif;
    span {
      color: $color-text-disabled;
    }
    &.not--margin {
      margin-bottom: 0;
    }
    &.bold {
      font-family: ConnectNegrita, sans-serif;
      font-weight: bold;
      &.less {
        color: $color-text-warning;
      }
      &.plus {
        color: $color-successful;
      }
      &--successful {
        &.less {
          color: $color-text-warning;

          &::before {
            content: '-';
            margin-right: 0.2rem;
          }
        }
        &.plus {
          color: $color-successful;
          &::before {
            content: '+';
            margin-right: 0.2rem;
          }
        }
      }
    }
  }
}
.service__info {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  margin-top: 34px;
  img {
    height: 24px;
    width: 24px;
  }
}
