.have__account__container {
  display: flex;
  justify-content: center;
  span {
    margin: 0 0.5rem 0 0;
    font-weight: 200;
    line-height: 1.125rem;
    letter-spacing: 0.05rem;
  }
}
