@import 'assets/styles/variables';

.popup__addon {
  &__container {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    min-height: 23.75rem;
    gap: 0.5rem;
    background-color: #f8fcff;
    gap: 1rem;
  }

  &__mb-8 {
    margin-bottom: 8px;
  }
  &__close {
    margin: 0 0 0 auto;
  }
  &__icons {
    width: 60px;
    height: 60px;
  }
  &__body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__title {
      h1 {
        text-transform: uppercase;
        margin: 0;
      }
      p {
        margin: 0;
        font-family: ConnectLigera, Arial, Helvetica, sans-serif;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      &__tags {
        display: flex;
        gap: 0.5rem;
      }
      &__info {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.8px;
        .bold {
          font-family: ConnectNegrita, Helvetica, sans-serif;
        }
        .orange {
          color: $elementos-ui-naranja-01;
        }
      }
    }
  }
  &__actions {
    display: flex;
    gap: 0.5rem;
    margin-top: 24px;
    .popup__addon__button {
      flex: 1;
      padding: 1rem 0;
    }
  }
}
