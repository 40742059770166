@import 'assets/styles/variables';

.tooltip {
  &__container {
    height: 24px;
    width: 24px;
    cursor: pointer;
    color: #ca651a;
    position: absolute;
    right: -2px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      .tooltip__text {
        visibility: visible;
      }
    }
  }
  &__icon {
    height: 100%;
    width: 100%;
  }
  &__text {
    visibility: hidden;
    width: max-content;
    background-color: #f6f8fa;
    box-shadow: 0 8px 32px 0 rgba(21, 13, 0, 0.1);
    color: #717478;
    text-align: center;
    border-radius: 6px;
    padding: 6px;
    position: absolute;
    z-index: 1;
    top: -16px;
    font-size: 11px;
    right: 110%;
    max-width: 200px;
  }
}
