@import 'assets/styles/animations';
.pop__up__overlay {
  align-items: center;
  backdrop-filter: blur(7px);
  background: #001D3DD9;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;

  .pop__up__container {
    margin: 0 1.438rem;
    max-width: 20.688rem;
    width: 100%;
    @include zoomIn;
  }
}
