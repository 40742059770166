@import 'assets/styles/variables';

.item__credit__card {
  &__container {
    cursor: pointer;
    padding: 16px 10px;
    margin-bottom: 0.938rem;
    flex-direction: row;
    gap: 0.5rem;
  }
  &__content {
    display: flex;
    justify-content: space-between;
  }
  &__logo {
    height: 1rem;
    width: 3.125rem;
  }
  &__brand {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 5.19rem;
    margin-right: 1rem;
  }
  &__digits {
    color: $elementos-ui-azul-01;
    font-family: ConnectRegular, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 200;
    line-height: 1.125rem;
    letter-spacing: 0.8px;
    strong {
      font-family: ConnectNegrita;
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1.125rem;
      letter-spacing: 0.8px;
    }
  }
  &__info {
    border-left: 1px solid $dark-medium-contrast;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
