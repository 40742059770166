.button__btn__close {
  background: none;
  border: none;
  cursor: pointer;
  height: fit-content;
  margin: 0;
  outline: none;
  padding: 0;
  width: inherit;

  svg {
    height: 0.625rem;
    width: 0.625rem;
    margin: 0.5rem;
    &.light {
      path {
        stroke: #fff;
      }
    }
    &.dark {
      path {
        stroke: #324552;
      }
    }

    &.medium {
      height: 0.938rem;
      width: 0.938rem;
    }
  }
}
