@import 'assets/styles/variables';
@import 'assets/styles/animations';

.item__buy__product {
  &__container {
    margin-bottom: 1.5rem;
  }

  &__tag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.7rem;
    span {
      font-family: ConnectLigeraItalica;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.00938rem;
      text-transform: uppercase;
    }
  }
}
