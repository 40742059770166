.profile {
  &__container {
    margin: 1.875rem auto 0 auto;
    max-width: 26.688rem;
    padding: 0 1rem;
    &__options {
      margin-top: 1.875rem;
    }
  }
  &__option {
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    letter-spacing: 0.44px;
    line-height: 1.156rem;
    margin-bottom: 0.625rem;
    padding: 1.219rem 1.396rem 1.344rem 1.396rem;
  }
  &__divider {
    margin: 11px 0 30px 0;
  }
}
