@import 'assets/styles/variables';
@import 'assets/styles/animations';

.popup__detail__accordion {
  &__container {
    position: fixed;
    top: 3.5rem;
    left: 0;
    right: 0;
    transition: all 1s ease;
    -moz-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    z-index: 1;
    overflow: hidden;
    &--open {
      height: 100%;
      background-color: rgba(21, 21, 21, 0.25);
    }
  }
  &__header {
    align-items: center;
    color: $color-white;
    background-color: $elementos-ui-naranja-01;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    &__title {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-family: ConnectNegrita;
      font-size: 0.875rem;
      line-height: 1rem;
      letter-spacing: 0.0125rem;
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    &__control {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      cursor: pointer;
      img.rotar {
        transform: rotate(180deg);
      }
    }
  }
  &__body {
    height: 0;
    background-color: $color-white;
    padding: 0 1rem;
    border-left: 2px solid $elementos-ui-azul-05;
    border-right: 2px solid $elementos-ui-azul-05;
    overflow: hidden;

    .popup__detail__decorator__container {
      position: absolute;
      bottom: -6px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      padding: 0 1rem;
      gap: 0.5rem;
      flex-wrap: wrap;
      overflow: hidden;
      height: 16px;
      .popup__detail__decorator__step {
        height: 1.2rem;
        width: 1.2rem;
        border: 2px solid $elementos-ui-azul-05;
        border-radius: 50%;
        background-color: rgba(21, 21, 21, 0.25);
      }
    }
    &--open {
      height: 100%;
      @include fadeInDownBig;
    }
    &__content {
      max-width: 22.5rem;
      margin: 0 auto 1rem auto;
      height: 80vh;
      overflow-y: scroll;
    }
  }
}
