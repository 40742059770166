@import 'assets/styles/variables';

.edit__property {
  &__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
    margin: 0 auto;
    max-width: 26.688rem;
    padding: 1.875rem;

    .edit__property__icon {
      height: 2.5rem;
      margin-bottom: 1.25rem;
      width: 5rem;
    }
    .edit__property__form__container {
      margin: 1.25rem 0 2rem 0;
    }
  }
}
