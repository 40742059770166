@import 'assets/styles/variables';

.history__card {
  &__container {
    margin-bottom: 0.625rem;
    .button__more__options__container {
      position: absolute;
      top: 12px;
      right: 16px;
      display: none;
    }
  }
  &__content {
    padding: 1rem;
    position: relative;
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }
  &__title {
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    line-height: 1.3rem;
    .title--wrap {
      word-wrap: break-word;
      max-width: 12rem;
    }
    .title--bold {
      font-family: ConnectNegrita, sans-serif;
      text-transform: uppercase;
      &.row {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &__detail {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #586983;
    font-family: ConnectLigera, sans-serif;
    line-height: 18px;

    &__address {
      color: $light-high-contrast;
      font-size: 0.875rem;
      line-height: 1.3rem;
      word-wrap: break-word;
      max-width: 100%;
    }
  }
  &__service {
    display: flex;
    justify-content: space-between;
  }
}
