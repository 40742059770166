@import 'assets/styles/variables';

.button__add__property {
  align-items: center;
  background-color: transparent;
  border: 1px dashed $color-successful;
  border-radius: 0.625rem;
  outline: none;
  color: $color-successful;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  flex-direction: column;
  padding: 1.529rem 0 0.625rem;
  max-width: 10.313rem;

  &.disabled {
    border: 1px dashed $accent-blue-04;
    color: $accent-blue-04;
    cursor: default;
  }

  &__icon {
    align-items: center;
    border-radius: 50%;
    border: 2px solid $color-successful;
    display: flex;
    height: 2rem;
    justify-content: center;
    position: relative;
    margin-bottom: 0.404rem;
    width: 2rem;

    &.disabled {
      border: 2px solid $accent-blue-04;
      &::after,
      &::before {
        border: 2px solid $accent-blue-04;
      }
    }

    &::after {
      border-radius: 8px;
      border: 2px solid $color-successful;
      content: '';
      height: 0.938rem;
      position: absolute;
    }

    &::before {
      border-radius: 8px;
      border: 2px solid $color-successful;
      content: '';
      position: absolute;
      width: 0.938rem;
    }
  }
}
