@import 'assets/styles/variables';
.container__navbar {
  background: $elementos-ui-naranja-01;
  box-shadow: 0px 4px 6px -2px rgb(0 0 0 / 13%);
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1;
  font-family: ConnectNegrita;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.7999999523162842px;
  text-align: left;
  text-transform: uppercase;
  ;

  &.light {
    background-color: transparent;
    box-shadow: none;
    animation: enhance-header both;
    animation-timeline: scroll(root block);
    animation-range: 0 100px;
  }

  &.not--padding__bottom {
    padding: 1.25rem 0.875rem 0;
  }
}

@keyframes enhance-header {
  to {
    background-color: $light-component-background-01;
    box-shadow: rgba(241, 91, 43, 0.15) 5px 0px 4px 0px;
  }
}
