.item__product {
  &__container {
    margin-bottom: 0.625rem;
  }

  &__more__options {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }

  &__content {
    padding: 1rem;
    position: relative;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    
  }
  &__title {
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    line-height: 1.3rem;
    .title--bold {
      font-family: ConnectNegrita, sans-serif;
      text-transform: uppercase;
      &.row {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &__detail {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #586983;
    font-family: ConnectLigera, sans-serif;
    line-height: 18px;

  }
  &__status {
    display: flex;
    justify-content: space-between;
  }
  &__chips-left {
    display: flex;
    gap: 0.4rem;
  }

  &__chips-right {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }

  &__icon-pasarela {
    width: 16px;
    height: 16px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
