@import 'assets/styles/variables';
.connect__link {
  &__label {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
    text-decoration: none;
    &.small {
      font-size: ConnectNegrita;
      font-weight: 700;
      letter-spacing: 0.8px;
    }
  }
  &__container {
    cursor: pointer;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    &.disabled {
      cursor: default;
      .connect__link__label {
        color: $elementos-ui-gris;
      }
      .connect__link__icon--path {
        fill: $elementos-ui-gris;
      }
    }
    &.primary {
      .connect__link__label {
        color: $elementos-ui-naranja-01;
      }
      .connect__link__icon {
        width: 13px;
        height: 13px;
        &--path {
          fill: $elementos-ui-naranja-01;
        }
      }
    }
    &.secondary {
      .connect__link__label {
        color: $elementos-ui-azul-01;
      }
      .connect__link__icon {
        width: 13px;
        height: 13px;
        &--path {
          fill: $elementos-ui-azul-01;
        }
      }
    }
  }
}
