@import 'assets/styles/variables';
.button__index__property {
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid $accent-orange-04;
  color: $accent-orange-04;
  cursor: pointer;
  font-size: 0.625rem;
  height: 1.375rem;
  line-height: 1rem;
  margin: 4.5px;
  outline: none;
  padding: 0;
  text-align: center;
  width: 1.375rem;
  cursor: default;

  &.disabled {
    cursor: default;
    opacity: 0.25;
    pointer-events: all;
  }

  &.completed {
    border: none;
    opacity: 0.5;
  }
}

.button__index__completed {
  height: 1.375rem;
  width: 1.375rem;
}
