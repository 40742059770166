@import 'assets/styles/variables';

.container__from__forgot-password {
  margin: 0 auto $font-size-m;
  max-width: 400px;
}

.container__message__forgot-password {
  margin: 0 auto 3rem;
  max-width: 341px;
}

.forgor__pasword {
  &__container {
    padding: 1.875rem;
  }
  &__message {
    margin: 1rem 0 1.438rem 0;
    line-height: 1.25rem;
    letter-spacing: 0.25px;
  }
  &__button {
    margin: 1.25rem 0 8.313rem auto;
    img {
      margin-left: 0.692rem;
    }
  }
}
