@import 'assets/styles/mixin-base';
@import 'assets/styles/variables';

.settings__options__Logout {
  align-items: center;
  color: $elementos-ui-azul-01;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;

  img {
    height: 1rem;
    margin-right: 0.5rem;
    width: 1rem;
  }

  &__user {
    cursor: pointer;
    text-transform: lowercase;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    svg {
      width: 1rem;
    }
  }
}
.settings__options__container {
  margin: 0 auto;
  max-width: 26.688rem;
  padding: 1.875rem 1rem 3.75rem;
  width: 100%;
  min-height: 61vh;

  &__title {
    display: flex;
    justify-content: space-between;

    img {
      width: 6.25rem;
      height: 6.25rem;
    }
  }
  &__content {
    display: flex;
    flex-direction: row;
    margin-top: 1.875rem;
  }
  .settings__options__logo {
    display: block;
    margin: 0 auto 1rem;
  }
}
.settings__content__properties {
  padding-left: 3rem;
  width: 100%;
}
.settings__label {
  max-width: 13.5625rem;
  line-height: 1.25rem;
  margin: 0;
  font-family: 'ConnectLigera', sans-serif;
}
.carousel__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 28.5px;
  text-transform: capitalize;
}
