@import 'assets/styles/variables';
.property {
  &__container {
    margin: 0 auto 4rem auto;
    max-width: 26.688rem;
    &__history {
      margin-top: 1.872rem;
    }
  }
  &__nav__container {
    display: flex;
    justify-content: space-between;
  }
  &__info__header {
    display: flex;
    flex-direction: column;

    img {
      width: 3rem;
      height: 3rem;
      margin-bottom: 0.5rem;
    }
  }
  &__title {
    color: $elementos-ui-naranja-01;
    font-family: ConnectNegrita, Prompt;
    font-weight: 500;
    text-transform: uppercase;
  }
  &__actions__container {
    display: flex;
    text-transform: uppercase;
    margin-top: 0.719rem;
    gap: 0.5rem;

    button {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1rem;
      letter-spacing: 0.05rem;
      font-family: ConnectNegrita, Prompt;
      background: none;
      border: none;
      color: $accent-blue-04;
      cursor: pointer;
      outline: none;
      padding: 0;
    }
    .property__actions__divider {
      background-color: $elementos-ui-azul-03;
      height: 100%;
      width: 1px;
    }
  }
  &__properties__container {
    display: flex;
    padding: 2.438rem 1.438rem 0.5rem;
    text-transform: capitalize;
    flex-wrap: wrap;

    & > div {
      margin-right: 0.5rem;
    }
  }
  &__notify__container {
    padding: 1.875rem 1.5rem;

    .list {
      padding: 0 0 0 1rem;
      margin: 0;
    }
  }
}
