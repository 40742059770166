@import 'assets/styles/variables';

.title__whit__imagen {
  &__contianer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    &.margin__small {
      margin-bottom: 0;
    }
  }
}
