@import 'assets/styles/variables';

.item__properti {
  &__container {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  &__content {
    font-family: Inter-Medium, sans-serif;
    margin-left: 1rem;

    .item__properti__properties {
      display: flex;
      flex-wrap: wrap;

      &__info {
        color: #868686;
        display: flex;
        flex-flow: column wrap;
        font-size: 0.625rem;

        & > span {
          margin-right: 1rem;
        }
      }
    }
  }

  &__icon {
    height: auto;
    width: 3.75rem;
  }

  &__title {
    font-size: 0.875rem;
    margin-bottom: 0.625rem;
  }

  &__status {
    bottom: -0.5rem;
    position: absolute;
    right: 0.375rem;
  }
}
