@import 'assets/styles/variables';
@import 'assets/styles/mixin-base';

.item__add__property {
  &__container {
    border: 1px solid $elementos-ui-azul-03;
    background: $color-white;
    display: flex;
    justify-content: space-between;
    padding: 0.625rem 0.704rem 0.594rem 0.97rem;

    @include for-mobile-small {
      flex-direction: column;
    }

    &__icon {
      align-items: flex-start;
      display: flex;
      justify-content: center;
      margin-right: auto;
      color: $color-text-disabled;
      align-items: center;

      img {
        margin-right: 12px;
        width: 2.5rem;
        height: 2.5rem;
      }
      &__detail {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .item_type {
          display: flex;
          flex-direction: column;
          span {
            color: $light-high-contrast;
            text-transform: capitalize;
            font-size: 1.125rem;
            line-height: 1.5rem;
            letter-spacing: 0.08125rem;
            &.price__unit {
              text-transform: lowercase;
              font-family: ConenctRegular, sans-serif;
              font-size: 12px;
              line-height: 1rem;
              letter-spacing: 0.5px;
              color: #586983;
            }
          }
        }
        .item_price {
          font-size: 0.75rem;
          line-height: 1rem;
          font-family: ConnectNegrita;
          letter-spacing: 0.8px;
          color: $elementos-ui-azul-01;
        }
      }
    }
    &__divider {
      background-color: $dark-medium-contrast;
      width: 1px;
    }
    &__info {
      align-items: flex-start;
      display: flex;
      margin-left: auto;
      button {
        align-items: center;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid $color-primary;
        cursor: pointer;
        display: flex;
        height: 1.146rem;
        justify-content: center;
        padding: 0;
        text-align: center;
        outline: none;
        width: 1.146rem;
        margin-top: 6px;

        &.disabled {
          border: 1px solid $elementos-ui-gris;
          cursor: default;
          &::before {
            background-color: $elementos-ui-gris;
          }
        }

        &::before {
          background-color: $color-primary;
          content: '';
          height: 1px;
          width: 8px;
        }
        &.less {
          margin-right: 0.938rem;
        }
        &.plus {
          margin-left: 0.938rem;

          &::after {
            background-color: $color-primary;
            content: '';
            height: 8px;
            position: absolute;
            width: 1px;
          }
          &.disabled {
            &::after {
              background-color: $dark-medium-contrast;
            }
          }
        }
      }
      div {
        align-items: center;
        display: flex;
        height: 1.875rem;
        justify-content: center;
        width: 3.75rem;
        &.border {
          border-left: 1px solid $elementos-ui-azul-03;
          border-right: 1px solid $elementos-ui-azul-03;
        }

        h4 {
          font-family: ConnectNegra, sans-serif;
          color: $elementos-ui-azul-01;
          overflow: hidden;
          font-size: 1.125rem;
          line-height: 1.5rem;
          letter-spacing: 0.08125rem;
          text-transform: uppercase;
        }
      }
    }
  }
}
