.container__loading__screen__auth {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
