@import 'assets/styles/variables';

.button__social {
  background-color: $elementos-ui-naranja-01;
  border-radius: 50%;
  padding: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 0px rgba(241, 91, 43, 0.1),
    2px 3px 8px 0px rgba(241, 91, 43, 0.1),
    6px 13px 14px 0px rgba(241, 91, 43, 0.09),
    14px 29px 19px 0px rgba(241, 91, 43, 0.05),
    25px 51px 23px 0px rgba(241, 91, 43, 0.01),
    40px 79px 25px 0px rgba(241, 91, 43, 0);
  &__icon {
    max-height: 100%;
    max-width: 100%;
  }

  &:hover {
    background-color: $elementos-ui-naranja-03;
    box-shadow: 0px 0px 0px 0px rgba(241, 91, 43, 0.1),
      0px 0px 1px 0px rgba(241, 91, 43, 0.1),
      2px 1px 2px 0px rgba(241, 91, 43, 0.09),
      4px 3px 3px 0px rgba(241, 91, 43, 0.05),
      7px 6px 4px 0px rgba(241, 91, 43, 0.01),
      10px 9px 4px 0px rgba(241, 91, 43, 0);
  }

  &:active {
    background-color: $elementos-ui-azul-01;
    box-shadow: none;
  }

  &.disabled {
    background-color: $elementos-ui-gris;
    cursor: not-allowed;
  }
}
