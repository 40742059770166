@import 'assets/styles/variables';
@import 'assets/styles/animations';

.properties__container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 26.688rem;
  padding: 1rem 0.5rem;
  width: 100%;
  background-color: #f8fcff;
  margin-bottom: 1.5rem;
  @include fadeIn;

  .properties__property__container {
    padding: 0 0.5rem;

    .properties__available {
      font-family: ConnectLigera, Prompt;
      color: $elementos-ui-azul-03;
      margin: 1rem 0 0 0;
      font-size: 0.875rem;
      line-height: 1.063rem;
    }
    &__items {
      display: grid;
      gap: 0.938rem;
      grid-template-columns: repeat(auto-fill, minmax(9.313rem, 1fr));
      margin: 1rem 0 0.5rem;
    }
    .more__options {
      display: flex;
      color: $elementos-ui-azul-01;
      cursor: pointer;
      grid-column: span 2;
      align-items: center;
      width: max-content;
      margin-left: auto;
      font-family: ConnectNegrita;
      font-size: 0.875rem;
      line-height: 1rem;
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      gap: 0.25rem;
      img {
        width: 1rem;
        height: 1rem;
      }
    }

    &__items__empty {
      padding: 1rem;

      img {
        height: 3.813rem;
        width: 6.188rem;
      }
      p {
        font-size: 1rem;
        line-height: 18.5px;
        letter-spacing: 0.44px;
        margin: 20px 0 0;
      }
    }
  }
}
.properties__title__container {
  padding: 2rem 1rem;

  p {
    color: $color-text-disabled;
    font-size: 0.875rem;
    letter-spacing: 0.25px;
    line-height: 1.25rem;
    margin: 0;
  }
}
