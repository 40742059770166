@import 'assets/styles/variables';
.item__list {
  &__contianer {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-family: ConnectLigera, sans-serif;
    line-height: 1.125rem;
    letter-spacing: 0.05rem;
    margin-bottom: 0.5rem;
    a {
      color: initial;
    }
  }
  &__text {
    font-family: ConnectLigera, Arial, Helvetica, sans-serif;
    font-size: 0.875rem;
    font-weight: 200;
    line-height: 18px;
    letter-spacing: 0.800000011920929px;
    text-align: left;
  }
  &__image {
    margin-right: 0.5rem;
  }
}
