@import 'assets/styles/variables';

.item__periodicity__type {
  &__container {
    cursor: pointer;
    padding: 0.75rem 0.8125rem 0.75rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    &.disabled {
      background-color: #e2e8f0;
    }
  }
  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &__price {
      font-size: 1.125rem;
      line-height: 1.5rem;
      letter-spacing: 0.08125rem;
      &.selected {
        color: $elementos-ui-naranja-01;
      }
    }
    &__detail {
      font-size: 1.125rem;
      line-height: 1.5rem;
      letter-spacing: 0.08125rem;
    }
  }
}
