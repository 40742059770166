@import 'assets/styles/variables';

.select__complement {
  &__container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }

  &__wraper {
    display: flex;
    .select__indicator {
      margin-right: 0.875rem;
      width: 8.688rem;
    }
  }

  &__content {
    display: flex;
  }

  &--error {
    color: $alertas-error;
    margin-top: -1rem;
    font-family: ConnectRegular, sans-serif;
    text-align: right;
    font-size: 12px;
    line-height: 1rem;
    letter-spacing: 0.5px;
  }
}
