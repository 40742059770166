@import 'assets/styles/variables';

.chips__container {
  background-color: #eef7ff;
  border-radius: 6.25rem;
  color: $elementos-ui-azul-02;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 4px 8px;
  width: max-content;
  font-weight: 400;
  font-family: ConnectRegular, sans-serif;
  text-transform: uppercase;
  margin: 0;
  &.pointer {
    cursor: pointer;
  }
}
