@import 'assets/styles/variables';
.item__menu__container {
  cursor: pointer;

  .item__menu {
    align-items: center;
    color: $elementos-ui-naranja-01;
    display: flex;
    font-size: $font-size;
    justify-content: space-between;
    letter-spacing: 0.44px;
    padding: 1rem 0.5rem 1rem 1rem;
    gap: 16px;
    border-radius: 800px;
    border: 1px solid $elementos-ui-naranja-01;
    background: $color-white;
    box-shadow: 0px 0px 0px 0px rgba(241, 91, 43, 0.1),
      1.6372px 2.4558px 6.54879px 0px rgba(241, 91, 43, 0.1),
      4.91159px 10.64178px 11.46038px 0px rgba(241, 91, 43, 0.09),
      11.46038px 23.73936px 15.55337px 0px rgba(241, 91, 43, 0.05),
      20.46496px 41.74852px 18.82776px 0px rgba(241, 91, 43, 0.01),
      32.74394px 64.66928px 20.46496px 0px rgba(241, 91, 43, 0);

    &__info {
      align-items: center;
      display: flex;
      position: relative;

      img {
        width: 2rem;
        height: 2rem;
      }
    }
    &__labels {
      margin-left: 1rem;
      &__title {
        display: flex;
        gap: 0.41rem;
        align-items: center;
        font-family: ConnectNegrita;
        font-size: 0.875rem;
        line-height: 1rem;
        letter-spacing: 0.0125rem;
        text-transform: uppercase;
        img {
          width: 1.22788rem;
          height: 1.22788rem;
        }
      }
      span {
        color: #001d3d;
        font-family: ConnectLigera;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0.05rem;
      }
    }
  }
}
