@import 'assets/styles/variables';
.tap__container {
  cursor: pointer;
  font-family: ConnectNegra, sans-serif;
  display: flex;
  padding: 0 0 12px 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  gap: 0.25rem;
  color: $elementos-ui-naranja-01;
  border-bottom: 4px solid;
  z-index: 1;
  .tap__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  &.disabled {
    color: #A3BED6;
    border-bottom: 4px solid transparent;
  }
}
