@import 'assets/styles/variables';

.tag__alert {
  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.1875rem 0.625rem 0.3125rem 0.625rem;
    gap: 0.125rem;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
    &.rounded {
      border-radius: 6.25rem;
    }
    &--medium {
      padding: 0.25rem 1rem;
      gap: 0.5rem;
    }
    &.success {
      background-color: $alertas-exito-claro;
      color: $alertas-exito;
    }
    &.error {
      background-color: $alertas-error-claro;
      color: $alertas-error;
    }
    &.warning {
      background-color: $alertas-advertencia-claro;
      color: $alertas-advertencia;
    }
  }
  &__icon {
    width: 0.875rem;
    height: 0.875rem;
  }
}
