@import 'assets/styles/variables';

.popup__freemiun {
  &__container {
    background-color: $elementos-ui-naranja-01;
    padding: 2rem 2rem 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
    @media (min-width: 1100px) {
      padding: 2.5rem;
    }
  }
  &__content {
    @media (min-width: 915px) {
      height: 100%;
    }
    &__row {
      display: flex;
      flex-direction: column;
      @media (min-width: 1100px) {
        flex-direction: row;
      }
    }
    &__left {
      display: flex;
      flex-direction: column;
    }
    &__title {
      font-family: ConnectNegra, Arial, Helvetica, sans-serif;
      font-size: 21.91px;
      font-weight: 1000;
      line-height: 29.21px;
      letter-spacing: 1.1865782737731934px;
      text-align: left;
      text-transform: uppercase;
      color: $color-white;

      @media (min-width: 1100px) {
        font-size: 40.12px;
        line-height: 53.49px;
        letter-spacing: 2.17301082611084px;
      }
    }
    &__text {
      font-family: ConnectRegular, Arial, Helvetica, sans-serif;
      font-weight: 400;
      color: $color-white;
      margin: 0 0 0.5rem 0;
      font-size: 10.12px;
      line-height: 15.18px;
      letter-spacing: 0.5060102939605713px;
      text-align: left;
      & strong {
        font-family: ConnectNegra, Arial, Helvetica, sans-serif;
        font-size: 14.34px;
        font-weight: 900;
        line-height: 15.18px;
        letter-spacing: 0.5060102939605713px;
        color: $elementos-ui-azul-01;
      }

      @media (min-width: 1100px) {
        font-size: 24.67px;
        font-weight: 400;
        line-height: 37.01px;
        letter-spacing: 1.2335938215255737px;
        & strong {
          font-size: 34.97px;
          font-weight: 900;
          line-height: 37.01px;
          letter-spacing: 1.2335938215255737px;
        }
      }
    }

    &__actions {
      &.mobile {
        display: flex;
        flex-direction: column;
        @media (min-width: 1100px) {
          display: none;
        }
      }
      &.desktop {
        display: none;
        flex-direction: column;

        @media (min-width: 1100px) {
          display: flex;
        }
      }
    }

    &__button {
      width: auto;
      margin: 1.5rem 0 0.5rem 0;
      & .freemiun__button {
        width: auto;
      }
    }

    &__terms {
      font-family: ConnectItalica, Arial, Helvetica, sans-serif;
      font-size: 10px;
      font-style: italic;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 1.4738787412643433px;
      text-align: left;
      color: $color-white;
      margin: 0;
    }

    &__image {
      width: 176px;
      height: 125px;
      @media (min-width: 915px) {
        width: 240px;
        height: 173px;
      }
      @media (min-width: 1100px) {
        width: 549px;
        height: 406px;
      }
    }
  }
  &__header {
    display: flex;
    justify-content: flex-end;
  }
  &__popup {
    @media (min-width: 915px) {
      max-width: 369px !important;
    }
    @media (min-width: 1100px) {
      max-width: 1242px !important;
    }
  }
}
