@import 'assets/styles/variables';

$animate-duration-fast: 0.4s;
$animate-duration: 1s;
$animate-duration-slow: 1.5s;

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    transform: scale3d(0.8, 0.8, 0.8);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    transform: scale3d(0.8, 0.8, 0.8);
  }

  50% {
    opacity: 1;
  }
}

@mixin zoomIn {
  animation: $animate-duration-fast both zoomIn;
  -webkit-animation: $animate-duration-fast both zoomIn;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@mixin spin {
  animation: spin $animate-duration infinite linear;
}

@keyframes changeColor {
  20% {
    opacity: 0.1;
  }

  50%,
  55% {
    opacity: 0.2;
  }

  to {
    opacity: 0.7;
  }
}

@-webkit-keyframes changeColor {
  20% {
    opacity: 0.1;
  }

  50%,
  55% {
    opacity: 0.2;
  }

  to {
    opacity: 0.7;
  }
}

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%,
  55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@-webkit-keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%,
  55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@mixin bounceOut {
  animation: bounceOut calc($animate-duration) linear forwards;
  &::after {
    content: '';
    position: absolute;
    background-color: $bg-error;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: changeColor 1 linear forwards;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@mixin fadeIn {
  animation: $animate-duration both fadeIn;
  -webkit-animation: $animate-duration both fadeIn;
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@mixin fadeInDownBig {
  animation: $animate-duration-fast both fadeInDownBig;
  -webkit-animation: $animate-duration-fast both fadeInDownBig;
}
