@import 'assets/styles/variables';
.header__steps {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__close {
    position: absolute;
    right: 1.375rem;
    width: auto;
    top: 0.8rem;
  }
  &__info {
    align-items: center;
    display: flex;
    font-family: ConnectNegrita, sans-serif;
    .header__steps__step {
      color: $color-white;
      cursor: default;
      &.light {
        color: $nav-bar-blue-disabled;
      }
      .header__steps__step__title {
        display: inline-block;
        text-transform: uppercase;
      }

      &.active {
        color: $color-white;
        cursor: pointer;
        &.light {
          color: $elementos-ui-azul-01;
        }
        + .header__steps__step.active {
          color: $color-white;
          &::before {
            content: url('/assets/images/header_indicator.svg');
          }
          &.light {
            color: $elementos-ui-azul-01;
          }
        }
      }
      + .header__steps__step {
        display: flex;
        align-items: center;
        &::before {
          content: url('/assets/images/header_indicator_disabled.svg');
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }

  &__progress {
    background-color: $nav-bar-blue-disabled;
    display: flex;
    height: 0.344rem;
    left: 0;
    margin-top: 0.9rem;
    position: absolute;
    width: 100%;

    &.not--progress {
      height: 0;
    }
    .bar__progress {
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      background-color: $nav-bar-blue-progress;
      height: 100%;
      transition: all 0.3s ease;
    }
  }
}
