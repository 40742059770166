@import 'assets/styles/variables';

.update__password {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.875rem 1.825rem 0.062rem;
    .have__account__container {
      margin: 1.875rem auto 3rem 0;
    }
  }
  &__from {
    &__text {
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #54565A;
    }
    &__content {
      width: 100%;
    }
    &__button {
      margin: 1.875rem 0 1.25rem auto;
    }
  }
}
