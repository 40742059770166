@import 'assets/styles/variables';
@import 'assets/styles/animations';
.services__history {
  &__container {
    padding: 0rem 0.875rem 4.906rem;
    @include fadeIn;
    overflow-x: hidden;
  }

  &__description {
    font-family: ConnectLigera, Prompt;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 200;
    line-height: 1.125rem;
    letter-spacing: 0.05rem;
    margin: 0.5rem 0 0.938rem;
  }
  &__filters {
    display: flex;
    justify-content: space-between;
    margin: 0.938rem 0 1.25rem;
    &.small {
      margin: 0.938rem 0 0.625rem;
    }
    .filter__status {
      margin-right: 0.625rem;
      width: 7.938rem;
    }
    .filter__month {
      width: 4.938rem;
    }
  }
}
