@import 'assets/styles/animations';
@import 'assets/styles/mixin-base';

.payments {
  &__container {
    @include fadeIn;
    display: flex;
    flex-direction: column;
    padding: 0.75rem 0 0;
    overflow: hidden;
    width: 100%;
    margin-bottom: 3.125rem;
    gap: 2rem;
    @include for-desktop-small {
      overflow: visible;
    }
    &__items {
      display: grid;
      gap: 0.938rem;
      grid-template-columns: repeat(auto-fill, minmax(10.3133rem, 1fr));
      margin: 0.938rem 0 0rem;
      @include fadeIn;
    }
    &__section {
      padding: 14px 1rem 69px 1rem;
      background: var(--fondos-blanco, #fff);
    }
  }
  &__content {
    margin-bottom: 3rem;
    padding: 0 0.938rem;
    &.not--padding {
      padding: 0;
    }
  }
  &__title {
    line-height: 2rem;
    &__alert {
      margin-bottom: 1rem;
      .title {
        color: $color-white;
        margin-bottom: 0.5rem;
        font-family: ConnectNegrita, Arial, Helvetica, sans-serif;
      }
      .detail {
        color: $color-white;
        letter-spacing: 0.8px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 200;
      }
    }
    &__container {
      padding: 2rem 1rem;
      p {
        color: $elementos-ui-azul-02;
        font-family: ConnectLigera;
        margin: 0;
        font-size: 0.875rem;
        line-height: 1.125rem;
        letter-spacing: 0.05rem;
      }
    }
  }

  &__loader {
    &__container {
      position: relative;
      height: 64px;
      width: 64px;
    }
    @include spin;
    position: relative;
    border-top: 4px solid rgba(255, 248, 234);
    border-right: 4px solid rgba(255, 248, 234);
    border-bottom: 4px solid rgba(255, 248, 234);
    border-left: 4px solid $alertas-advertencia;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    &__content {
      border-radius: 0.5rem;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:after {
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  }
}

.item_popup_custom_service {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  &__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .title {
    color: #586983;
    font-size: 12px;
    font-family: 'ConnectNegrita', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 16px;
    letter-spacing: 0.2;
    word-wrap: break-word;
  }

  .icon {
    cursor: pointer;
  }

  .underline {
    text-decoration: underline;
    cursor: pointer;
  }
}

.icon_pasarela {
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  z-index: 1;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
