@import 'assets/styles/mixin-base';
@import 'assets/styles/variables';

.container__btns__checkbox {
  margin: 0 0 1rem 0;
  .btns__checkbox--link {
    color: $color-text-primary;
    display: block;
    margin-left: 25px;
    text-align: left;
    text-decoration: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
  }
}
