@import 'assets/styles/variables';

.tag__label {
  &__container {
    font-family: ConnectNegrita, sans-serif;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.375rem 0.5rem 0.5rem 0.5rem;
    gap: 0.25rem;
    width: max-content;
    &.primary {
      color: $elementos-ui-naranja-01;
      background-color: $elementos-ui-naranja-05;
    }
    &.secondary {
      color: $elementos-ui-azul-02;
      background-color: $elementos-ui-azul-05;
    }
    &.rounded {
      border-radius: 6.25rem;
    }
  }
  &__icon {
    width: 0.875rem;
    height: 0.875rem;
    cursor: pointer;
    .tag__label__icon__rect {
      fill: $elementos-ui-azul-01;
    }
    .tag__label__icon__path {
      fill: $color-white;
    }
  }
}
