@mixin for-tablet {
  @media (min-width: 768px) and (max-width: 1279px) {
    @content;
  }
}

@mixin for-desktop {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin for-desktop-small {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-mobile-small {
  @media (max-width: 351px) {
    @content;
  }
}

@mixin for-other-device {
  @media (min-width: 376px) {
    @content;
  }
}
