@import 'assets/styles/variables';

.popup__required {
  &__message {
    margin-bottom: 1.625rem;
    color: $elementos-ui-azul-01;
    font-family: ConnectNegrita, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }
}
