@import 'assets/styles/variables';

.item__periodicity {
  &__title {
    margin-bottom: 1.5rem;
  }
  &__options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__discount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: -0.35rem;
  }
}
