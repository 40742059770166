@import 'assets/styles/mixin-base';
@import 'assets/styles/variables';

.illustration__personal__information {
  height: 7.5rem;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
.checkout__personal__information {
  &__padding {
    padding: 0 1.875rem;
    &.personal__information__title {
      margin-top: 5rem;
    }
    &--small {
      padding: 0 0.938rem;
    }
  }
  &__container {
    .container__btns {
      display: flex;
      flex-direction: column;
      margin: 0 auto;

      .container__btns__checkbox {
        display: flex;
        gap: 24px;
        flex-direction: column;
        margin: 12px 0 40px 0;

        a {
          color: $elementos-ui-azul-01;
          font-size: 0.875rem;
          line-height: 1rem;
          letter-spacing: 0.05rem;
          display: block;
          margin-left: 25px;
          text-align: left;
          text-decoration: none;
        }
      }
    }
    &__button {
      margin: 0 0 40px 0;
      position: sticky;
      bottom: 2rem;
    }
  }
  &__title {
    margin-top: 1.875rem;
  }

  &__divider__section {
    margin: 30px 0;
  }

  &__divider {
    margin: 20px 0 30px 0;
    &--small {
      margin: 0 0 1.25rem 0;
    }
  }
}
.alert__error__checkbox {
  display: flex;
  gap: 4px;
  font-family: ConnectRegular, sans-serif;
  font-weight: 200;
  font-size: 12px;
  line-height: 1rem;
  letter-spacing: 0.8px;
  background-color: #fff6f6;
  align-items: center;
  padding: 0 6px 2px 6px;
  border-radius: 100px;
  width: max-content;
  margin-top: 8px;
}
