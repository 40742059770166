@import 'assets/styles/variables';

.container__card {
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  background-color: $color-white;
  border: 1px solid $elementos-ui-azul-05;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 0.5s ease;

  &--selected {
    border: 1px solid $elementos-ui-naranja-01;
  }
}
