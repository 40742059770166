@import 'assets/styles/variables';
@import 'assets/styles/mixin-base';

@mixin title-h1 {
  color: $light-high-contrast;
  font-family: ConnectNegrita, sans-serif;
  font-size: $font-size-l;
  line-height: 2rem;
  margin: 0;
}

@mixin title-h2 {
  font-family: ConnectNegra, Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-weight: 900;
  line-height: 40px;
  letter-spacing: 1px;
  text-align: left;
  margin: 0;
  color: $color-white;
}

@mixin title-h3 {
  color: $elementos-ui-azul-01;
  font-size: 1.5rem;
  font-family: ConnectNegra, sans-serif;
  line-height: 2rem;
  letter-spacing: 0.08125rem;
  text-transform: uppercase;
  margin: 0;
}

@mixin title-h4 {
  color: $light-high-contrast;
  font-family: ConnectNegrita, sans-serif;
  font-size: 2.125rem;
  line-height: 2.75rem;
  margin: 0;
}

@mixin title-h5 {
  font-size: 0.875rem;
  color: $elementos-ui-azul-01;
  margin: 0;
  font-family: ConnectNegrita, Arial, Helvetica, sans-serif;
  line-height: 1rem;
  letter-spacing: 0.0125rem;
  text-transform: uppercase;
}

@mixin title-h6 {
  color: $light-high-contrast;
  margin: 0;
  font-family: ConnectNegrita, Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0.0675rem;
  text-transform: uppercase;
}

@mixin title-header {
  color: $accent-orange-02;
  font-family: ConnectNegrita, sans-serif;
  text-transform: lowercase;
  letter-spacing: 0.75px;

  &::first-letter {
    text-transform: uppercase;
  }
}
