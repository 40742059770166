@import 'assets/styles/variables';

.create__properties {
  &__container {
    max-width: 26.688rem;
    margin: 1.875rem auto 3.75rem auto;
  }

  &__detail__accordion {
    margin: 0 1rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    letter-spacing: 0.05rem;
    strong {
      font-family: ConnectNegrita, Arial, Helvetica, sans-serif;
    }
    .info {
      margin-top: 1rem;
    }
  }

  &__form {
    margin: 0 1.875rem;
    &__title {
      margin-top: 2.5rem;
    }
    p {
      font-family: ConnectLigera;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.05rem;
      margin: 0.5rem 0 2rem 0;
    }
    .title__whit__imagen__contianer {
      margin-bottom: 0;
    }
    .create__properties__iamge__property {
      height: 6.25rem;
      width: 6.25rem;
      border-radius: 50%;
      background-color: $light-component-background-01;
      object-fit: fill;
    }
  }
  &__divider {
    margin: 1.875rem 0;
  }
  &__message {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.25px;
    margin: 1.25rem 0;
  }
  &__complete {
    margin: 2rem 0;
  }
  &__atributes {
    margin: 1rem 0 1.5rem;
    &__divider {
      height: 0.0625rem;
      background-color: $elementos-ui-azul-03;
    }
  }
  &__warning {
    display: flex;
    flex-direction: column;
    margin: 3rem 0 3rem 0;
    gap: 3rem;

    img {
      height: 1.483rem;
      margin-bottom: 0.539rem;
      width: 1.696rem;
    }
  }
}
