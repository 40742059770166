@import 'assets/styles/variables';

.container__stepper {
  display: flex;
  justify-content: center;
  margin: 2.5rem 0 1.25rem;
  max-width: 100%;
  padding: 0;
  width: 26.125rem;

  div {
    align-items: center;
    color: $light-component-background-01;
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    list-style-type: none;
    position: relative;
    width: 3.344rem;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;

    &.navigate {
      cursor: pointer;
    }

    p {
      color: $color-disabled;
      margin: 0.3rem auto 0;
      max-width: 94px;
      text-align: center;

      &.active {
        color: $color-primary;
        font-size: 14px;
        font-weight: 600;
      }

      &.disabled {
        color: $color-disabled;
        font-size: 0.7rem;
      }

      &.completed {
        color: $color-primary;
        font-size: 0.7rem;
      }
    }

    .step__label {
      background-color: $color-background-primary;
      border: 2px solid #c4c4c4;
      border-radius: 50%;
      display: flex;
      height: 20px;
      justify-content: center;
      margin: 3px;
      width: 20px;
      z-index: 1;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      transition: all 0.4s ease;

      &.active {
        border: 2px solid $color-primary;
        height: 25.83px;
        margin: 0;
        width: 25.83px;
      }

      &.completed {
        background-color: $color-primary;
        background-image: url('/assets/images/check.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 0.75rem;
        border-color: $color-primary;
        color: $light-component-background-01;
      }

      span {
        background-color: $color-primary;
        border-radius: 50%;
        height: 0.563rem;
        width: 0.563rem;
      }
    }

    &::after {
      background-color: #ccc;
      content: '';
      height: 2px;
      left: -50%;
      position: absolute;
      top: 13px;
      width: 100%;
      z-index: 0;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }

    &:first-child::after {
      content: none;
    }

    &.active::after,
    &.completed::after {
      background-color: $color-primary;
    }
  }
}
