@import 'assets/styles/animations';

.new__pasword {
  &__container {
    padding: 3.75rem 1.875rem 3.125rem 1.875rem;
  }
  &__message {
    margin: 0.3rem 0 0.688rem 0;
    line-height: 1.25rem;
  }
  &__requirements {
    color: $light-medium-contrast;
    margin: 0.625rem 0 1.25rem 0;
    padding: 1.656rem 1.531rem;
    span {
      font-family: Inter-Bold, sans-serif;
      line-height: 1.25rem;
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }
    ul {
      margin: 0;
      padding: 0 0 0 1.3rem;
      li {
        line-height: 1.25rem;
      }
    }
  }
}
