.subscriptions {
  &__container {
    display: flex;
    flex-direction: column;
    padding: 0.75rem 0 0;
    width: 100%;
    margin-bottom: 3.125rem;
    gap: 2.5rem;
    &__section {
      padding: 14px 1rem 0px 1rem;
      background: var(--fondos-blanco, #fff);
    }
  }
  &__content {
    margin-bottom: 3rem;
    padding: 0 0.938rem;
    &.not--padding {
      padding: 0;
    }
    &__items {
      display: grid;
      gap: 0.938rem;
      grid-template-columns: 1fr;
      margin: 0.938rem 0 0rem;
    }
  }
  &__payment {
    background: var(--fondos-blanco, #fff);
  }
}

