@import 'assets/styles/variables';

.container__divider {
  width: 100%;

  hr {
    background-color: $accent-blue-02;
    border: 0;
    height: 1px;
    margin: 30px 0;

    &.margin {
      &--small {
        margin: 10px 0;
      }
      &--not {
        margin: 0;
      }
    }

    &.bar {
      background-color: $light-component-background-01;
      height: 0.625rem;
    }
  }
}
