@import 'assets/styles/variables';

.container__select__generic {
  display: flex;
  flex-direction: column;
  position: relative;
}
.select__generic__base {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}
.select__generic {
  align-items: center;
  appearance: none;
  background-color: $color-white;
  border: 1px solid $elementos-ui-azul-03;
  cursor: pointer;
  display: flex;
  font-family: ConnectRegular, sans-serif;
  font-size: 0.875rem;
  height: 3.125rem;
  margin-bottom: 1rem;
  outline: none;
  padding: 1.375rem 2rem 0.5rem 1rem;
  position: relative;
  width: 100%;

  &.placeholder {
    padding: 0.688rem 2rem 0.688rem 15px;
  }
  &:focus {
    + .select__label {
      font-size: $font-size-s;
      top: 0.375rem;
    }
  }
  ~ .select__label {
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    color: $color-text-disabled;
    font-size: $font-size-m;
    left: 1rem;
    position: absolute;
    top: 1rem;
    transition: all 0.3s ease;
    font-family: ConnectLigera, sans-serif;

    &.label--error {
      color: $alertas-error;

      img {
        filter: hue-rotate(314deg);
      }
    }
    &.select__label--value {
      font-size: $font-size-s;
      top: 0.375rem;
    }
    img {
      margin-left: 5px;
      width: 6px;
    }
  }
  &.error {
    border: 1px solid $alertas-error;
    background: $alertas-error-claro;
  }
  &.small {
    border-radius: 0.313rem;
    height: 2.188rem;
    padding: 0.688rem 2rem 0.688rem 0.688rem;
    text-align: center;
    width: 3.75rem;

    ~ .select__generic__icon {
      right: 0.5rem;
      top: 0.9rem;
    }
  }
  &.disabled {
    border: 1px solid $elementos-ui-gris;
    background: #F5F5F5;
    color: #586983;
    cursor: default;
    pointer-events: none;
    
    + .select__label {
      color: #586983;
      .select__image__required {
        filter: grayscale(1) opacity(1);
      }
    }
  }
  &__text {
    align-items: center;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;

    &__icon {
      height: 1.172rem;
      left: 1.313rem;
      position: absolute;
      top: 0.976rem;
    }
    &__required {
      margin-left: 5px;
      width: 6px;
    }
  }
  &__icon {
    position: absolute;
    right: 1.313rem;
    top: 1.375rem;
    &.disabled {
      filter: grayscale(1) opacity(0.4);
    }
  }
  &__options {
    left: 0;
    max-height: 212px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    width: 100%;
    z-index: 2;
    background: $color-white;
    /* Resaltar contenido Low/Sombra Azul */
    box-shadow: 0px 0px 0px 0px rgba(0, 29, 61, 0.1),
      0px 0px 1px 0px rgba(0, 29, 61, 0.1),
      2px 1px 2px 0px rgba(0, 29, 61, 0.09),
      4px 3px 3px 0px rgba(0, 29, 61, 0.05),
      7px 6px 4px 0px rgba(0, 29, 61, 0.01), 10px 9px 4px 0px rgba(0, 29, 61, 0);
  }
  &__option {
    align-items: center;
    color: $light-high-contrast;
    cursor: pointer;
    display: flex;
    font-family: ConnectRegular, sans-serif;
    justify-content: flex-start;
    padding: 0.1875rem 1rem 0.3125rem 1rem;
    text-align: left;
    border-bottom: 0.5px solid $elementos-ui-azul-05;

    &__info {
      display: flex;
      flex-direction: column;
    }

    &__icon {
      height: 1.3rem;
      width: 1.3rem;
      margin-left: 0.364rem;
    }

    &.small {
      align-items: center;
      height: auto;
      text-align: center;
    }
    span {
      color: $color-text-disabled;
      font-family: Inter-Regular, sans-serif;
      font-size: 0.75rem;
    }
    &.disabled {
      background-color: $light-grey;
      cursor: default;
    }
    &:hover:not(.disabled),
    &:active:not(.disabled) {
      background-color: $color-azul-02;
      color: $light-component-background-01;
      span {
        color: $light-component-background-01;
      }
      .select__generic__option__icon {
        filter: brightness(0) invert(1);
      }
    }
  }
}
.select__error {
  color: $alertas-error;
  font-size: 0.75rem;
  margin-bottom: 1.375rem;
  text-align: left;
}
