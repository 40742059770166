@import 'assets/styles/variables';
.container__background__screen__auth {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  .container__screen__auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 312px;
    margin: 2.75rem auto 6.25rem auto;
    position: relative;
    .header__form__auth {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-bottom: 24px;
      .logo__form__auth {
        height: 4rem;
        width: 4.5rem;
        aspect-ratio: 1/1;
      }
      .step__form__auth {
        font-family: ConnectItalica;
        font-size: 12px;
        font-style: italic;
        font-weight: 200;
        line-height: 16px;
        letter-spacing: 1.159999966621399px;
        text-align: center;
        color: $elementos-ui-naranja-01;
      }
    }
    .text__from__auth {
      text-align: center;
      font-weight: 200;
      font-size: 1rem;
      letter-spacing: 0.8px;
      line-height: 24px;
      margin: 0.5rem 0 2rem 0;
      font-family: ConnectLigera, Arial, Helvetica, sans-serif;
    }
    h5 {
      margin-bottom: 1rem;
    }
    .container__form__auth {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .header__logo {
      margin-top: 4.5rem;
    }
    .link__from__auth {
      cursor: pointer;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      text-decoration: underline;
      color: $elementos-ui-naranja-01;
      margin: 0 0 1rem 0;
      border: none;
      background-color: transparent;
      &.light {
        margin: 24px auto 0 0;
        color: $color-white;
      }
    }
    .container__list__from__auth {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      color: $color-white;
      .divider__from__auth {
        width: 100%;
        height: 1px;
        background-color: $color-white;
      }
      .title__list__from__auth {
        list-style-type: none;
        color: $color-white;
        h5 {
          color: $color-white;
          margin: 0;
        }
        ul {
          margin: 0 auto 0 0;
          padding: 0 0 0 2rem;
          li.item__list__from__auth {
            font-size: 0.75rem;
            line-height: 18px;
            letter-spacing: 0.88px;
            font-family: ConnectLigera;
          }
        }
      }
    }
  }

  .decoration__form__auth {
    position: absolute;
    width: 49.5rem;
    height: 49.5rem;
    border-radius: 50%;
    top: 21rem;
    background-color: $elementos-ui-naranja-01;
    z-index: -1;
    .decoration__image {
      position: absolute;
      &--right {
        top: -2rem;
        right: 0rem;
      }
      &--left {
        left: -10rem;
        top: 10rem;
      }
    }
  }
}