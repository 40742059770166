.renewal__subscription {
  &__container {
    margin: 1.875rem auto 0 auto;
    max-width: 26.688rem;
    padding: 0 1rem;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
