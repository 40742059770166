.button__add__item__skeleton__container {
  display: flex;
  align-items: center;
  .label {
    height: 1.063rem;
    width: 6.25rem;
    margin-right: 0.364rem;
  }
  .button {
    height: 1.46rem;
    width: 1.46rem;
    border-radius: 50%;
  }
}
