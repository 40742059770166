@import 'assets/styles/variables';

.card__info {
  &__container {
    background-color: $nav-bar-blue-disabled;
    display: flex;
    padding: 2rem 1rem;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin: 0 auto;
    position: relative;
    &__image {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      top: 0;
      background: url('/assets/animations/estrellas.gif') no-repeat right 0;
      .image {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
  &__message {
    font-family: ConnectLigera;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    text-transform: uppercase;
    z-index: 1;
  }
  &__options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;

    .card__info__option {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      .card__info__link {
        color: $elementos-ui-azul-01;
        font-family: ConnectNegra, sans-serif;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 1.2999999523162842px;
        text-align: left;
        text-decoration: none;
      }
    }
  }
}
