@import 'assets/styles/variables';

.item__empty {
  &__container {
    min-height: 160px;
    position: relative;
    padding: 1.5rem 1rem;
    background-color: $color-white;
    display: flex;
    gap: 1rem;
    background: $elementos-ui-azul-05;
    box-shadow: 0px 0px 0px 0px rgba(0, 29, 61, 0.1),
      0px 0px 1px 0px rgba(0, 29, 61, 0.1),
      2px 1px 2px 0px rgba(0, 29, 61, 0.09),
      4px 3px 3px 0px rgba(0, 29, 61, 0.05),
      7px 6px 4px 0px rgba(0, 29, 61, 0.01), 10px 9px 4px 0px rgba(0, 29, 61, 0);
  }
  &__content {
    align-items: center;
    border-radius: 0.625rem;
    display: flex;
    padding-left: 1.375rem;
    width: 100%;
  }
  &__info {
    display: flex;
    flex-direction: column;
    max-width: 222px;
    h3 {
      margin-bottom: 0.5rem;
    }
    .item__empty__title {
      font-family: ConnectRegular;
      margin: 0 0 0.5rem 0;
      line-height: 17px;
      letter-spacing: 0.1px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.8px;
    }
    .item__empty__link {
      color: $accent-orange-04;
      margin-top: 0.625rem;
      line-height: 1.063rem;
      letter-spacing: 0.1px;
    }
    .item__empty__button {
      width: fit-content;
    }
  }
  &__icon {
    background-color: $elementos-ui-naranja-01;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    padding: 12.42px;
    position: absolute;
    right: -41px;
    bottom: -40px;
  }
}
